import { useLocation, useNavigate, useParams } from "react-router";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Layout from "common/components/layout";
import CashFlowPage from "./CashFlowPage";
import IncomeStatementPage from "./IncomeStatementPage";
import BalanceSheet from "./balanceSheet";
import RatioAnalysis from "./ratioAnalysis";
import Narrative from "./narrative/narrative";
function ProtegeReports(props) {

  const navigate = useNavigate();
  return (
    <Layout>
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light" onClick={() => navigate(-1)}>
              Protégé /
            </span>{" "}
            Reports
          </h4>
        </div>
      </div>
      <div className="card-body">
        <div className="col-md-12">
          <Tabs
            defaultActiveKey="cashflow"
            id="justify-tab-example"
            className="mb-3"
            justify
          >
            <Tab eventKey="cashflow" title="Cash Flow">
              <CashFlowPage />
            </Tab>
            <Tab eventKey="incomestatement" title="Income Statement">
              <IncomeStatementPage />
            </Tab>
            <Tab eventKey="balancesheet" title="Balance Sheet">
              <BalanceSheet />
            </Tab>
            <Tab eventKey="ratioanalysis" title="Ratio Analysis">
              <RatioAnalysis />
            </Tab>
            <Tab eventKey="narrative" title="Narrative">
              <Narrative />
            </Tab>
          </Tabs>
        </div>
      </div>

    </Layout>
  );
}

export default ProtegeReports;
