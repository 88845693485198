import {
  Tabs,
  Tab,
  Modal,
  Row,
  Button,
  Col,
  Form,
  Card,
  Container,
  Nav
} from "react-bootstrap";
import Layout from "common/components/layout"; 
import CashFlow from "./CashFlow";
import RepaymentBackUp from "./RepaymentBackUp";
import CashFlowBackUp from "./IncomeSalesRevenue";
import { useLocation, useNavigate, useParams } from "react-router";
import StartUpCost from "./StartUpCostReport";
import StartUpCostReport from "./StartUpCostReport";
function CashFlowPage() {
  const navigate = useNavigate();
  return (
    <>
      {/* <h4 className="fw-bold py-3">Cash flow</h4> */}
      
      <Tab.Container id="left-tabs-example" defaultActiveKey="cashflow">
        <div className="nav-align-top mb-4">
          <Nav variant="pills" className="mb-3">
            <Nav.Item>
              <Nav.Link eventKey="cashflow">
                <span className="bx-profile">Cash Flow</span>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="repayment">
              <span className="bx-profile">Repayment Back Up</span>

              </Nav.Link>
            </Nav.Item>
           
            <Nav.Item>
              <Nav.Link eventKey="startup">
              <span className="bx-profile">Startup Cost and Sources Of Fund</span>
              </Nav.Link>
            </Nav.Item>
          
          </Nav>
          <Tab.Content>
            <Tab.Pane eventKey="cashflow">
              <CashFlow />
            </Tab.Pane>
            <Tab.Pane eventKey="repayment">
              <RepaymentBackUp />
            </Tab.Pane>
            <Tab.Pane eventKey="startup">
              <StartUpCostReport />
              {/* <RepaymentBackUp /> */}
            </Tab.Pane>
           
          </Tab.Content>
        </div>
      </Tab.Container>
      <hr className="my-5" />
      <div className="content-backdrop fade"></div>
    </>
  );
}
export default CashFlowPage;
