import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function IncomeFixedCost(props) {
  const operation = useParams().op;
  const params = useParams();
  const [fixedCost, setFixedCost] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [fixedCostNonCash, setFixedCostNonCash] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    }, 
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostCash, setFixedCostCash] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    }, 
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [interest, setInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    }, 
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setFixedCost(data.data[0].fixedCost ? data.data[0].fixedCost : {});
          setIsLoader(false);
          let totalnonCash = fixedCostNonCash;
          let totalCash = fixedCostCash;
          data.data[0].fixedCost.fieldLineItemsName.filter((x, index) => {
            totalCash.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" &&  x.lineItemName !=="Interest"  ? parseFloat(x.details['gestPeriod']) : 0;
            for(let i=1; i<=12; i++){
              totalCash.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !=="Interest"? parseFloat(x.details[i]) : 0;
              totalnonCash.details[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;
          }

          
          for(let i=1; i<=3; i++){
            totalCash.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" &&  x.lineItemName !=="Interest" ? parseFloat(x.aggregate[i]) : 0;
            totalnonCash.aggregate[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;
            }
            
          });

          setFixedCostCash(totalCash);
          setFixedCostNonCash(totalnonCash);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }

  if (fixedCost && fixedCost.fieldLineItemsName) {
    return (
      <>
        <h5>Fixed Cost Details</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th className="text-center md-width">Description</th>
                <th className="text-center md-width">Gestation Period</th>
                <th className="text-center md-width">Month 1</th>
                <th className="text-center md-width">Month 2</th>
                <th className="text-center md-width">Month 3</th>
                <th className="text-center md-width">Month 4</th>
                <th className="text-center md-width">Month 5</th>
                <th className="text-center md-width">Month 6</th>
                <th className="text-center md-width">Month 7</th>
                <th className="text-center md-width">Month 8</th>
                <th className="text-center md-width">Month 9</th>
                <th className="text-center md-width">Month 10</th>
                <th className="text-center md-width">Month 11</th>
                <th className="text-center md-width">Month 12</th>
                <th className="text-center md-width">Year 1</th>
                <th className="text-center md-width">Year 2</th>
                <th className="text-center md-width">Year 3</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td colSpan="18"><strong>Cash</strong></td>
              </tr>
              {fixedCost.fieldLineItemsName && fixedCost.fieldLineItemsName.map((x, index) => {
                if (x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !=="Interest") {
                  return (
                    <tr key={index}>
                      <td className="text-right lg-width">
                        {x.lineItemName}
                      </td>
                      <td className="text-right">{getNumericFormat(x?.details?.['gestPeriod'])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[1])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[2])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[3])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[4])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[5])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[6])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[7])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[8])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[9])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[10])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[11])}</td>
                      <td className="text-right">{getNumericFormat(x?.details?.[12])}</td>
                      <td className="text-right">{getNumericFormat(x?.aggregate?.[1])}</td>
                      <td className="text-right">{getNumericFormat(x?.aggregate?.[2])}</td>
                      <td className="text-right">{getNumericFormat(x?.aggregate?.[3])}</td>
                    </tr>
                  );
                }
              })} 
               
              <tr>
              <td className="text-left"><strong> Subtotal Cash</strong></td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[3])}</td>
            </tr>
            {fixedCost.fieldLineItemsName && fixedCost.fieldLineItemsName.map((x, index) => {
                if (x.lineItemName === "Interest" ) {
                  return (
                    <tr key={index}>
                      <td className="text-left lg-width"><strong>{x.lineItemName} (Cash)</strong></td>

                      <td className="text-right">{getNumericFormat(x.details['gestPeriod'])}</td>
                      <td className="text-right">{getNumericFormat(x.details[1])}</td>
                      <td className="text-right">{getNumericFormat(x.details[2])}</td>
                      <td className="text-right">{getNumericFormat(x.details[3])}</td>
                      <td className="text-right">{getNumericFormat(x.details[4])}</td>
                      <td className="text-right">{getNumericFormat(x.details[5])}</td>
                      <td className="text-right">{getNumericFormat(x.details[6])}</td>
                      <td className="text-right">{getNumericFormat(x.details[7])}</td>
                      <td className="text-right">{getNumericFormat(x.details[8])}</td>
                      <td className="text-right">{getNumericFormat(x.details[9])}</td>
                      <td className="text-right">{getNumericFormat(x.details[10])}</td>
                      <td className="text-right">{getNumericFormat(x.details[11])}</td>
                      <td className="text-right">{getNumericFormat(x.details[12])}</td>
                      <td className="text-right">{getNumericFormat(x.aggregate[1])}</td>
                      <td className="text-right">{getNumericFormat(x.aggregate[2])}</td>
                      <td className="text-right">{getNumericFormat(x.aggregate[3])}</td>
                    </tr>
                  );
                }
              })} 

               <tr>
                <td><strong>Non Cash</strong></td>
              </tr>
              {fixedCost.fieldLineItemsName && fixedCost.fieldLineItemsName.map((x, index) => {
                if (x.lineItemName === "Dep + Amort (Non Cash)" || x.lineItemName === "Addtnl Dep (Non Cash)") {
                  return (
                    <tr key={index}>
                      <td className="text-right lg-width">
                        {x.lineItemName}
                      </td>
                      <td className="text-center">-</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[1])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[2])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[3])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[4])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[5])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[6])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[7])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[8])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[9])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[10])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[11])}</td>
                      <td className="text-right">{x.details && getNumericFormat(x.details[12])}</td>
                      <td className="text-right">{x.aggregate && getNumericFormat(x.aggregate[1])}</td>
                      <td className="text-right">{x.aggregate && getNumericFormat(x.aggregate[2])}</td>
                      <td className="text-right">{x.aggregate && getNumericFormat(x.aggregate[3])}</td>
                    </tr>
                  );
                }
              })}
<tr>
<td><strong>Total Non Cash</strong></td>
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostNonCash.aggregate[3])}</td>
            </tr>

            </tbody>
          </table>
        </div>
      </>
    );
  }
}
export default IncomeFixedCost;
