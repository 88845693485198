import "screens/protégé/style/narrative.css";
import { useParams, useNavigate, useLocation } from "react-router";
import { useEffect, useState } from "react";
import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import Layout from "common/components/layout";
import CustomModal from "common/components/CustomModal";
import axios from "axios";
import { ApiUrl } from "API/config";
import moment from "moment";
import Loader from "common/components/loader";
import editNarrative from "./editNarrative"
import CashFlow from "./../CashFlow";
import RepaymentBackUp from "../RepaymentBackUp";
import StartUpCostReport from "../StartUpCostReport";
import IncomeFixedCost from "../IncomeFixedCost";
import IncomeSalesRevenue from "../IncomeSalesRevenue";
import IncomeStatement from "../IncomeStatement";
import IncomeUnitEconomics from "../incomeUnitEconomics";
import IncomeProdPlan from "../incomeProdPlan";
import BalanceSheet from "../balanceSheet";
import RatioAnalysis from "../ratioAnalysis";
function Narrative(props) {
  const operation = useParams().op;
  const params = useParams();
  const route = useParams().route;
  const navigate = useNavigate();
  const location = useLocation()
  const [show, setShow] = useState(false);
  const editNarrativeHandler = () => {
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
  };
  let Year1 = 0;
  let Year2 = 0;
  let Year3 = 0;
  const [data, setData] = useState();
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [sourcesOfFundData, setSourcesOfFundData] = useState();
  const [narrativeContent, setNarrativeContent] = useState({
    executiveSummary1: "This is the Business Plan/Project Report for establishing",
    executiveSummary2: "The Business Idea is",
    executiveSummary3: "The entity is to be established as",
    executiveSummary4: "Total project cost is",
    executiveSummary5: "and it is planned to be met from the following sources.",
    keyNumbers: "The key performance numbers for the three years are tabulated below:",
    equipment1: "Equipment required are",
    equipment2: "These will be procured from",
    material1: "Various raw materials used are",
    material2: "Sources for these materials are",
    material3: "Payment terms for material acquisition are Both cash and Credit",
    humanResource: "Skills needed Handling Machines like Packing Machine and Automatic Machine. We will be employing Owner, Supervisor, Assistant, Machine Operator, Packers",
    utilities: "Space required 5000 Sqft. We will be constructing this space. Power requirement is 10.5 kWh. Water requirement is 10 Liters. Special storage is required for storing the incense sticks.",
    other: "Gestation period in this activity is 1 Month. Permissions required for starting this business include Registration and License Required, a NOC from the city's pollution control board authorities",
    customer1: "Our target customer is",
    customer2: "There are approximately",
    customer3: "potential customers in my operational area. I have determined this number by Market Survey by Online & Telephone. I expect approximately 20 From this to be my customers.",
    competition1: "There is",
    competition2: "Competitor in my area. Their positive points are",
    competition3: "Their areas of weakness are",
    competition4: "I have determined this through Personally Visiting the place",
    strategy: "My strategy for ensuring expected customer base is.... My plan for sales promotion by sharing Posters, Messages over Mobile, social media Etc.",
    salesAndSupply: "Product being sold is",
    salesAndSupply1: "We will sell to retailers and temples directly located at Bidar, Karnataka.",
    salesAndSupply2: "There will be Scented and Unscented Incense sticks with 2 and 1 SKU respectively. Number of sales staffs at the store will be 2. They will be on fixed salary. We will supply the product to our distributor in 2 and 1 different SKUs (Stock Keeping Units).",
    salesAndSupply3: "The SKUS and planned annual sales are as under:",
    salesAndSupply4: "Material will be procured on the basis of sales forecast. Production and material procurement will be planned based on sales forecast. Capacity utilization during the three years will be ……%, ………%, and ……% respectively.",
    sourcesOfFund1: "Funding needed for start-up is detailed in the following table. Total needed is",
    sourcesOfFund2: "The planned sources for meeting these needs are also tabulated.",
    sourcesOfFund3: "We will recruit required manpower with basic knowledge and skills in this business. If necessary, we'll provide training to enhance their knowledge and skills in the respective department they will be working, etc.",
    fixedCost: "Annual fixed cost for",
    pricing1: "The prices at which the products will be sold are: -",
    pricing2: "will be sold at the rate of",
    pricing3: "The selling prices were determined on basis of keeping unit cost, profit and competitors’ prices.",
    qualityAndUSP: "We will be selling Quality Incense Sticks with good packing so that retains the fragrance. UPS for our business is Long lasting fragrance and long sticks, 5% more burning time than other sticks.",
    risk: "Power Failure and Breakdown of Machines. Timely maintenance of Machines. If there is frequent power cut we may plan to add Generators to run the machines so it does not affect our production.",
    unitEconomics: "Unit economics of our product is positive, as seen from the following information.",

  });
  const [fixedCostExcludingInterest, setFixedCostExcludingInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostInterest, setFixedCostInterest] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netFixedAssets, setNetFixedAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalInflow, setTotalInflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [inputCredit, setInputCredit] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [startupCostData, setStartupCostData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [fixedCostCash, setFixedCostCash] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fixedCostNonCash, setFixedCostNonCash] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalOutflow, setTotalOutflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [additonalMachine, setAdditonalMachine] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [subTotalVariableCost, setSubTotalVariableCost] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalFixedExpense, setTotalFixedExpense] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [subTotalAssetAcquisition, setSubTotalAssetAcquisition] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalSalesRevenue, setTotalSalesRevenue] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalVariableCost, setTotalVariableCost] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [grossProfit, setGrossProfit] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTOnSale, setNetGSTOnSale] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [incometaxPaid, setIncometaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [taxPaid, setTaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [repayment, setRepayment] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  const [totalNetGSTPaid, setTotalNetGSTPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [openingBalance, setOpeningBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [closingBalance, setClosingBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  const [accountsReceivable, setAccountsReceivable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [gstReceivable, setGstReceivable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [prepaidExpenses, setPrepaidExpenses] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [accruedExpenses, setAccruedExpenses] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalCurrentAssets, setTotalCurrentAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalAssets, setTotalAssets] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTPayable, setNetGSTPayable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [incomeTax, setIncomeTax] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalCurrentLiabilities, setTotalCurrentLiabilities] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [otherLongTermDebt, setOtherLongTermDebt] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [equityCapital, setEquityCapital] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [profitAfterTax, setProfitAfterTax] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [currentPortion, setCurrentPortion] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [retainedEarnings, setRetainedEarnings] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [commission, setCommission] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [fgTransport, setFgTransport] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [creditCardFee, setCreditCardFee] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [accountsPayable, setAccountsPayable] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [otherIncome, setOtherIncome] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [materialInventory, setMaterialInventory] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [finishedGoodsInventory, setFinishedGoodsInventory] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [inventoryTurnover, setInventoryTurnover] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalFunding, setTotalFunding] = useState(0);

  const calcCreditCardFee = (noOfMonths, prices, percentage) => {
    var obj = {
      details: {
        gestPeriod: 0,
        1: 0,
        2: 0,
        3: 0,
        4: 0,
        5: 0,
        6: 0,
        7: 0,
        8: 0,
        9: 0,
        10: 0,
        11: 0,
        12: 0,
      },
      aggregate: {
        1: 0,
        2: 0,
        3: 0,
      }
    };
    for (let month = 0; month <= 14; month++) {

      if (month < 12) {
        if (month < noOfMonths) {
          obj.details[month + 1] = 0;
        } else {
          obj.details[month + 1] = prices.details[month + 1 - noOfMonths] * percentage / 100;
        }

      }
      else if (month === 12) {
        var preceedingAmt = Object.values(prices.details).slice(0, -noOfMonths).reduce((sum, total) => sum + total, 0)
        var preceedingAmtToInt = Object.values(prices.details).map(item => parseFloat(item) * percentage / 100)
        obj.aggregate[1] = preceedingAmtToInt.slice(0, 12 - noOfMonths).reduce((sum, total) => sum + total, 0)
      }
      else if (month === 13) {
        var secondYearPreceedingAmt = ((preceedingAmtToInt.slice(-noOfMonths).reduce((sum, total) => sum + total, 0)))
        obj.aggregate[2] = secondYearPreceedingAmt + (parseFloat(prices.aggregate[2]) - parseFloat(prices.aggregate[2] * noOfMonths / 12)) * percentage / 100
      }
      else {
        var thirdYearPreceedingAmt = ((parseFloat((prices.aggregate[2] * (noOfMonths) / 12) * percentage / 100)));
        obj.aggregate[3] = (parseFloat(prices.aggregate[3] * percentage / 100) - parseFloat(prices.aggregate[3] * noOfMonths / 12) * percentage / 100) + thirdYearPreceedingAmt;
      }

    }
    setCreditCardFee(obj);
    return obj;
  }

  const calculateTotalCostPerItem = (productValue) => {
    let productList = productValue.productList || [];
    let totalCostPerItems = [];

    for (const product of productList) {
      if (!product || !product.skuData) continue;

      for (const sku of product.skuData) {
        if (!sku) continue;

        let totalCost = parseFloat(sku.skuTotalVariableCostExcludingGST) + parseFloat(sku.skuGstAmtOnVariableCost);

        totalCostPerItems.push({
          skuName: sku.sku_description,
          totalCostPerItem: totalCost ? totalCost : 0
        });
      }
    }

    return totalCostPerItems;
  };

  // Calculating total quantity
  const calculateTotalQuantity = (productValue) => {
    let salesProjection = productValue.salesProjection?.fieldProductSkuName || [];
    let totalQuantities = [];

    for (const item of salesProjection) {
      if (!item || !item.details || !item.aggregate) continue;

      const skuQuantities = Object.values(item.details).map(detail => parseFloat(detail.prod_quantity));
      const aggregateQuantities = Object.values(item.aggregate).map(aggregateDetail => parseFloat(aggregateDetail.prod_quantity));
      const totalQty = [...skuQuantities, ...aggregateQuantities];

      totalQuantities.push({
        skuName: item.name,
        totalQty: totalQty
      });
    }

    return totalQuantities;
  };

  // Calculating total cost
  const calculateTotalCost = (totalCostPerItems, totalQuantities) => {
    let result = {
      month1: 0, month2: 0, month3: 0, month4: 0, month5: 0,
      month6: 0, month7: 0, month8: 0, month9: 0, month10: 0,
      month11: 0, month12: 0, month13: 0, month14: 0, month15: 0,
    };

    for (const prodQty of totalQuantities) {
      const skuName = prodQty.skuName;
      const totalQty = prodQty.totalQty;

      const product = totalCostPerItems.find(product => product.skuName === skuName);

      if (product) {
        const totalCost = parseFloat(product.totalCostPerItem);

        for (let j = 0; j < totalQty.length; j++) {
          const monthIndex = j + 1;
          const monthQty = parseFloat(totalQty[j]);
          result['month' + monthIndex] += totalCost * monthQty;
        }
      }
    }
    return result;
  };

  // Aggregate the result
  const aggregateResult = (result) => {
    let prodQuantityValue = {
      details: {
        gestPeriod: 0,
        1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0,
      },
      aggregate: {
        1: result.month13 || 0,
        2: result.month14 || 0,
        3: result.month15 || 0,
      }
    };

    for (let key in result) {
      if (key.startsWith("month")) {
        let monthNumber = parseInt(key.replace("month", ""));
        prodQuantityValue.details[monthNumber] = result[key];
      }
    }

    return prodQuantityValue;
  };



  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          let productValue;
          if (data.data[0].manufacturingIds) {
            setProductData(data.data[0].manufacturingIds)
            productValue = data.data[0].manufacturingIds;
          } else if (data.data[0].trading) {
            setProductData(data.data[0].trading)
            productValue = data.data[0].trading;
          } else if (data.data[0].service) {
            setProductData(data.data[0].service)
            productValue = data.data[0].service;
          } else if (data.data[0].animalhusbandry) {
            setProductData(data.data[0].animalhusbandry)
            productValue = data.data[0].animalhusbandry;
          } else if (data.data[0].agro) {
            setProductData(data.data[0].agro)
            productValue = data.data[0].agro;
          } else {

          }
          setData(data.data[0].personalInfoId);
          setBusinessData(data.data[0].businessInfoid);
          setStartupCostData(data.data[0].startupCost ? data.data[0].startupCost : {});
          setSourcesOfFundData(data.data[0].sourcesOfFund ? data.data[0].sourcesOfFund : {});
          var months = productValue ? parseInt(productValue?.salesProjection?.compositionOfSales?.Card?.noOfMonths) : 0;
          var prices = productValue ? productValue?.salesProjection?.compositionOfSales?.Card : {};
          var percentage = productValue ? productValue?.salesProjection?.pay : 0;
          var noOfMonths2 = months;
          var cred = calcCreditCardFee(months, prices, percentage)
          var obj = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          for (let month = 0; month <= 14; month++) {

            if (month < 12) {

              obj.details[month + 1] = prices.details[month + 1] * percentage / 100;


            }
            else if (month === 12) {
              var preceedingAmtToInt = Object.values(prices.details).map(item => parseFloat(item) * percentage / 100)
              obj.aggregate[1] = preceedingAmtToInt.reduce((sum, total) => sum + total, 0)
            }
            else if (month === 13) {
              obj.aggregate[2] = (parseFloat(prices.aggregate[2]) * percentage / 100)
            }
            else {
              obj.aggregate[3] = (parseFloat(prices.aggregate[3]) * percentage / 100)
            }

          }
          var obj2 = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          for (let month = 0; month <= 14; month++) {

            if (month < 12) {
              if (month < noOfMonths2) {
                obj2.details[month + 1] = 0;
              } else {
                obj2.details[month + 1] = prices.details[month + 1 - noOfMonths2] * percentage / 100;
              }

            }
            else if (month === 12) {
              var preceedingAmt = Object.values(prices.details).slice(0, -noOfMonths2).reduce((sum, total) => sum + total, 0)
              var preceedingAmtToInt = Object.values(prices.details).map(item => parseFloat(item) * percentage / 100)
              obj2.aggregate[1] = preceedingAmtToInt.slice(0, 12 - noOfMonths2).reduce((sum, total) => sum + total, 0)
            }
            else if (month === 13) {
              var secondYearPreceedingAmt = ((preceedingAmtToInt.slice(-noOfMonths2).reduce((sum, total) => sum + total, 0)))
              obj2.aggregate[2] = secondYearPreceedingAmt + (parseFloat(prices.aggregate[2]) - parseFloat(prices.aggregate[2] * noOfMonths2 / 12)) * percentage / 100
            }
            else {
              var thirdYearPreceedingAmt = ((parseFloat((prices.aggregate[2] * (noOfMonths2) / 12) * percentage / 100)));
              obj2.aggregate[3] = (parseFloat(prices.aggregate[3] * percentage / 100) - parseFloat(prices.aggregate[3] * noOfMonths2 / 12) * percentage / 100) + thirdYearPreceedingAmt;
            }

          }
          setIsLoader(false);

          let cashExcludingInterest = fixedCostExcludingInterest;
          let cashInterest = fixedCostInterest;
          let totalCash = fixedCostCash;
          let salesCommission = commission;
          let transportTotal = fgTransport;
          let totalFixedCost = totalFixedExpense;
          let nonCash = fixedCostNonCash;
          let totalFundingValue = totalFunding;
          data.data[0].fixedCost.fieldLineItemsName.filter((x, index) => {
            cashExcludingInterest.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.details['gestPeriod']) : 0;
            cashInterest.details['gestPeriod'] += x.lineItemName == "Interest" ? parseFloat(x.details['gestPeriod']) : 0;
            totalCash.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.details['gestPeriod']) : 0;
            for (let i = 1; i <= 12; i++) {
              cashExcludingInterest.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.details[i]) : 0;
              cashInterest.details[i] += x.lineItemName == "Interest" ? parseFloat(x.details[i]) : 0;
              totalCash.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;
              nonCash.details[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.details[i]) : 0;
              totalFixedCost.details[i] += parseFloat(x.details[i]);
            }
            totalFixedCost.details['gestPeriod'] += parseFloat(x.details['gestPeriod']);
            for (let i = 1; i <= 3; i++) {
              cashExcludingInterest.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" && x.lineItemName !== "Interest" ? parseFloat(x.aggregate[i]) : 0;
              cashInterest.aggregate[i] += x.lineItemName == "Interest" ? parseFloat(x.aggregate[i]) : 0;
              totalCash.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;
              nonCash.aggregate[i] += x.lineItemName == "Dep + Amort (Non Cash)" || x.lineItemName == "Addtnl Dep (Non Cash)" ? parseFloat(x.aggregate[i]) : 0;
              totalFixedCost.aggregate[i] += parseFloat(x.aggregate[i]);
            }
          });

          totalFundingValue = (data?.data?.[0]?.sourcesOfFund?.fieldLineItemsName
            ?.filter(item => item?.lineItemName !== "Own Fund")
            ?.reduce((total, item) => total + parseFloat(item?.Amount), 0));
          setFixedCostExcludingInterest(cashExcludingInterest);
          setFixedCostInterest(cashInterest);
          setFixedCostCash(totalCash);
          setFixedCostNonCash(nonCash);
          setTotalFixedExpense(totalFixedCost);
          setTotalFunding(totalFundingValue);

          const whichMonth = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth)
          let other = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          if (whichMonth > 0) {

            if (whichMonth <= 12) {

              other.details[whichMonth] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
              other.aggregate[1] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
            } else if (whichMonth <= 24) {
              other.aggregate[2] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)
            } else if (whichMonth <= 36) {
              other.aggregate[3] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)

            }

            setOtherIncome(other)
          }
          let accountsPayableCalc = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          }
          console.log(productValue);
          if (productValue?.productList[0].hasOwnProperty('numberOfItemsLessThan') && !productValue?.productList[0]?.numberOfItemsLessThan) {
            console.log('LessThan');
            for (let i = 1; i <= 12; i++) {
              for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
                let receipt = (parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.details?.receipt?.[i]));

                accountsPayableCalc.details[i] += parseFloat(receipt);
              }
            }

            for (let i = 1; i <= 3; i++) {
              for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
                let receipt = parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.aggregate?.receipt?.[i]);
                accountsPayableCalc.aggregate[i] += parseFloat(receipt);

              }
            }
            console.log(accountsPayableCalc)
          }
          else {


            for (let i = 1; i <= 12; i++) {
              for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
                let receipt = (parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.details?.receipt?.[i]) * parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.per_unit_cost_of_raw_material));

                accountsPayableCalc.details[i] += parseFloat(receipt);
              }
            }

            for (let i = 1; i <= 3; i++) {
              for (let j = 0; j < productValue?.rawMaterialProcurement?.fieldProductRMName.length; j++) {
                let receipt = parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.ReceiptPlan?.aggregate?.receipt?.[i]) * parseFloat(productValue?.rawMaterialProcurement?.fieldProductRMName[j]?.per_unit_cost_of_raw_material);
                accountsPayableCalc.aggregate[i] += parseFloat(receipt);

              }
            }
            console.log('else', accountsPayableCalc)

          }


          var totalVariableCost = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          }

          const totalCostPerItems = calculateTotalCostPerItem(productValue);
          const totalQuantities = calculateTotalQuantity(productValue);
          const result = calculateTotalCost(totalCostPerItems, totalQuantities);
          const prodQuantityValue = aggregateResult(result);





          let totalInflowValue = totalInflow;
          let equityCapitalValue = equityCapital;
          let sourcesOfFund = 0;
          data.data[0].sourcesOfFund.fieldLineItemsName.map(item => {
            sourcesOfFund += parseFloat(item.Amount)
            if (item.Description === "Own Fund") {
              equityCapitalValue.details['gestPeriod'] = parseFloat(item.Amount);
            }
          })
          totalInflowValue.details['gestPeriod'] = sourcesOfFund

          let subTotalVariable = subTotalVariableCost;
          subTotalVariable.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets) + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(data.data[0].startupCost.initialRawMaterialPurchase) + parseFloat(totalCash.details['gestPeriod'] + data.data[0].startupCost.initialCash);

          let subTotalAssetAcquisitionValue = subTotalAssetAcquisition;
          subTotalAssetAcquisitionValue.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets) + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets)

          let totalOutflowValue = totalOutflow;
          totalOutflowValue.details['gestPeriod'] = (data.data[0].startupCost?.totalDepreciableAssets + data.data[0].startupCost?.totalNonDepreciableAssets + data.data[0].startupCost?.totalIntangiblesAssets + data.data[0].startupCost?.totalDepositsAndAdvancesAssets) + parseFloat(data.data[0].startupCost?.initialRawMaterialPurchase) + totalCash?.details?.gestPeriod;

          //balance sheet calculation begin

          let netFixedAssetsValue = netFixedAssets;
          netFixedAssetsValue.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets);


          let inventoryTurnoverValue = inventoryTurnover;
          inventoryTurnoverValue.details['gestPeriod'] = 0;

          let accountsReceivableValue = accountsReceivable;
          accountsReceivableValue.details['gestPeriod'] = 0;

          let gstReceivableValue = gstReceivable;
          gstReceivableValue.details['gestPeriod'] = 0;


          let netGSTPayableValue = netGSTPayable;
          netGSTPayableValue.details['gestPeriod'] = 0;

          let currentPortionValue = currentPortion;
          currentPortionValue.details['gestPeriod'] = parseFloat(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.aggregate?.principle[1]) + parseFloat(data?.data?.[0]?.sourcesOfFund?.familyFriends?.aggregate?.principle[1]) + parseFloat(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.aggregate?.principle[1]) + parseFloat(data?.data?.[0]?.sourcesOfFund?.mfi?.aggregate?.principle[1]) + parseFloat(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.aggregate?.principle[1]) + parseFloat(data?.data?.[0]?.sourcesOfFund?.others?.aggregate?.principle[1]);

          let incomeTaxValue = incomeTax;
          incomeTaxValue.details['gestPeriod'] = 0;

          let materialInventoryValue = materialInventory;
          materialInventoryValue.details['gestPeriod'] = parseFloat(productValue?.rawMaterialProcurement?.initialInputCost)

          let finishedGoodsInventoryValue = finishedGoodsInventory;
          finishedGoodsInventoryValue.details['gestPeriod'] = 0;

          let otherLongTermDebtValue = otherLongTermDebt;
          otherLongTermDebtValue.details['gestPeriod'] = totalFundingValue - currentPortionValue.details['gestPeriod'];

          let profitAfterTaxValue = profitAfterTax;
          profitAfterTaxValue.details['gestPeriod'] = 0 - totalFixedExpense.details['gestPeriod'];

          let retainedEarningsValue = retainedEarnings;
          retainedEarningsValue.details['gestPeriod'] = 0 - totalFixedCost.details['gestPeriod'];

          let prepaidExpensesValue = prepaidExpenses;
          prepaidExpensesValue.details['gestPeriod'] = 0;

          let accruedExpensesValue = accruedExpenses;
          accruedExpensesValue.details['gestPeriod'] = 0;

          let inputCreditClaim = inputCredit;
          inputCreditClaim.details['gestPeriod'] = productValue?.rawMaterialProcurement?.initialInputCostGST;

          let totalCurrentLiabilitiesValue = totalCurrentLiabilities;
          totalCurrentLiabilitiesValue.details['gestPeriod'] = currentPortionValue.details['gestPeriod'];


          let accountsPayableValue = accountsPayable;
          let repaymentValue = repayment;
          let totalNetGSTPaidValue = totalNetGSTPaid;
          let totalSales = totalSalesRevenue;
          let totalVariable = totalVariableCost;
          let totalnetGSTOnSale = netGSTOnSale;
          let totalGrossProfit = grossProfit;
          let incometaxPaidValue = incometaxPaid;
          let totalTaxPaid = taxPaid;
          let additonalMachineValue = additonalMachine;
          let openingBalanceValue = openingBalance;
          let closingBalanceValue = closingBalance;
          let totalCurrentAssetsValue = totalCurrentAssets;
          let totalAssetsValue = totalAssets;
          let taxRateYear1 = data.data[0].businessInfoid.taxRateYear1 ? data.data[0].businessInfoid.taxRateYear1 : 0;
          let taxRateYear2 = data.data[0].businessInfoid.taxRateYear2 ? data.data[0].businessInfoid.taxRateYear2 : 0;
          let taxRateYear3 = data.data[0].businessInfoid.taxRateYear3 ? data.data[0].businessInfoid.taxRateYear3 : 0;


          //ratio

          for (let i = 1; i <= 12; i++) {

            additonalMachineValue.details[i] = parseFloat(data.data[0].startupCost.whichMonth) == i ? parseFloat(data.data[0].startupCost.totalCost) : 0;
            totalNetGSTPaidValue.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].gst_collected_on_sales) - parseFloat(productValue.rawMaterialProcurement.monthlyInputGSTPaid[i]) - (i === 1 ? parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST) : 0)
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            let subsidy = parseFloat(data.data[0].sourcesOfFund.whichMonth) == i ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
            totalInflowValue.details[i] = parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i]) + parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i]) + subsidy;
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            salesCommission.details[i] = productValue.salesProjection.fieldProductsName[0].commission.details[i];
            transportTotal.details[i] = productValue.salesProjection.fieldProductsName[0].transport.details[i];
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            subTotalVariable.details[i] = parseFloat(productValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[i]) + parseFloat(productValue.rawMaterialProcurement.monthlyInputGSTPaid[i]) + parseFloat(totalNetGSTPaidValue.details[i]) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].commission) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].transport) + parseFloat(cred.details[i]);
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) - (parseFloat(totalVariable?.details[i]) + parseFloat(commission?.details[i]) + parseFloat(fgTransport?.details[i])) - parseFloat(obj?.details[i]);
            repaymentValue.details[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.details.principle[i])
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            salesCommission.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.commission?.details[i]) || 0), 0);
            transportTotal.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.transport?.details[i]) || 0), 0);
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) - (parseFloat(totalVariable?.details[i]) + parseFloat(commission?.details[i]) + parseFloat(fgTransport?.details[i])) - parseFloat(obj?.details[i]);
            totalTaxPaid.details[i] = parseFloat((totalGrossProfit.details[i] + otherIncome.details[i] - totalFixedCost.details[i]) * taxRateYear1 / 100) < 0 ? 0 : parseFloat((totalGrossProfit.details[i] + otherIncome.details[i] - totalFixedCost.details[i]) * taxRateYear1 / 100)
            incometaxPaidValue.details[i] = totalTaxPaid.details[i];
            inputCreditClaim.details[i] = parseFloat(productValue?.salesProjection?.totalMonthlyValues?.details?.[i]?.variableCostGST) - parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i]) + (i > 1 ? parseFloat(inputCreditClaim.details[i - 1]) : 0);
            currentPortionValue.details[i] = parseFloat(currentPortionValue?.details['gestPeriod'])
              - (Object.values(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0)) - (Object.values(data?.data?.[0]?.sourcesOfFund?.others?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0)) - (Object.values(data?.data?.[0]?.sourcesOfFund?.mfi?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0)) - (Object.values(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0)) - (Object.values(data?.data?.[0]?.sourcesOfFund?.familyFriends?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0)) - (Object.values(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.details?.principle)?.slice(0, i)?.reduce((init, acc) => (parseFloat(init) + parseFloat(acc)), 0))
          }

          totalTaxPaid.details[3] = totalTaxPaid.details[1] + totalTaxPaid.details[2] + totalTaxPaid.details[3];
          totalTaxPaid.details[1] = 0;
          totalTaxPaid.details[2] = 0;
          totalTaxPaid.details[6] = totalTaxPaid.details[4] + totalTaxPaid.details[5] + totalTaxPaid.details[6];
          totalTaxPaid.details[4] = 0;
          totalTaxPaid.details[5] = 0;
          totalTaxPaid.details[9] = totalTaxPaid.details[7] + totalTaxPaid.details[8] + totalTaxPaid.details[9];
          totalTaxPaid.details[7] = 0;
          totalTaxPaid.details[8] = 0;
          totalTaxPaid.details[12] = totalTaxPaid.details[10] + totalTaxPaid.details[11] + totalTaxPaid.details[12];
          totalTaxPaid.details[10] = 0;
          totalTaxPaid.details[11] = 0;


          let subsidyYear1 = parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 12 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
          let subsidyYear2 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 12 && parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
          let subsidyYear3 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;


          closingBalanceValue.details['gestPeriod'] = totalInflowValue.details['gestPeriod'] - totalOutflowValue.details['gestPeriod'];
          totalCurrentAssetsValue.details['gestPeriod'] = closingBalanceValue.details['gestPeriod'] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(inputCreditClaim.details['gestPeriod']) + accountsReceivableValue.details['gestPeriod'] + gstReceivableValue.details['gestPeriod'] + parseFloat(materialInventoryValue.details['gestPeriod']) + parseFloat(finishedGoodsInventoryValue.details['gestPeriod']);
          totalAssetsValue.details['gestPeriod'] = netFixedAssetsValue.details['gestPeriod'] + totalCurrentAssetsValue.details['gestPeriod'];

          for (let i = 1; i <= 12; i++) {
            totalOutflowValue.details[i] = parseFloat(additonalMachineValue.details[i]) + subTotalVariable.details[i] + totalCash.details[i] + totalTaxPaid.details[i] + repaymentValue.details[i];
            let previousMonth = (i - 1) == 0 ? 'gestPeriod' : i - 1;
            openingBalanceValue.details[i] = closingBalanceValue.details[previousMonth];
            closingBalanceValue.details[i] = openingBalanceValue.details[i] + (totalInflowValue.details[i] - totalOutflowValue.details[i]);
            netFixedAssetsValue.details[i] = netFixedAssetsValue.details[previousMonth] - (nonCash.details[i] + additonalMachineValue.details[i]);
            accountsReceivableValue.details[i] = i > 1 ? (parseFloat(accountsReceivableValue.details[previousMonth]) + parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i])) : parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i]);
            gstReceivableValue.details[i] = i > 1 ? (parseFloat(gstReceivableValue.details[previousMonth]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i])) : parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i]);
            prepaidExpensesValue.details[i] = prepaidExpensesValue.details[previousMonth] + parseFloat(totalCash.details[i]) - parseFloat(cashInterest.details[i]) - parseFloat(cashExcludingInterest.details[i]);
            accruedExpensesValue.details[i] = accruedExpensesValue.details[previousMonth] + parseFloat(cashExcludingInterest.details[i]) + parseFloat(cashInterest.details[i]) - parseFloat(totalCash.details[i]);
            materialInventoryValue.details[i] = materialInventoryValue.details[previousMonth] + accountsPayableCalc.details[i] - prodQuantityValue.details[i]
            finishedGoodsInventoryValue.details[i] = finishedGoodsInventoryValue.details[previousMonth] + prodQuantityValue.details[i] - productValue?.salesProjection?.totalMonthlyValues?.details?.[i]?.variableCostB4GST - productValue?.salesProjection?.totalMonthlyValues?.details?.[i]?.variableCostGST
            totalCurrentAssetsValue.details[i] = prepaidExpensesValue.details[i] + closingBalanceValue.details[i] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(inputCreditClaim.details[i]) + parseFloat(accountsReceivable.details[i]) + parseFloat(gstReceivableValue.details[i]) + parseFloat(materialInventoryValue.details[i]) + parseFloat(finishedGoodsInventoryValue.details[i]);
            totalAssetsValue.details[i] = netFixedAssetsValue.details[i] + totalCurrentAssetsValue.details[i];
            netGSTPayableValue.details[i] = netGSTPayableValue.details[previousMonth] + totalnetGSTOnSale.details[i] - totalNetGSTPaidValue.details[i];
            incomeTaxValue.details[i] = incomeTaxValue.details[previousMonth] + incometaxPaidValue.details[i] - totalTaxPaid.details[i];
            equityCapitalValue.details[i] = equityCapitalValue.details['gestPeriod'];
            profitAfterTaxValue.details[i] = ((totalGrossProfit.details[i] - totalFixedCost.details[i]) - incometaxPaidValue.details[i]);
            retainedEarningsValue.details[i] = retainedEarningsValue.details[previousMonth] + profitAfterTaxValue.details[i] + other.details[i];
            accountsPayableValue.details[i] = accountsPayableValue.details[previousMonth] + accountsPayableCalc?.details[i] - parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST[i]) - parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid[i]) + parseFloat(obj?.details[i]) - parseFloat(obj2?.details[i]);
            totalCurrentLiabilitiesValue.details[i] = accountsPayableValue.details[i] + incomeTaxValue.details[i] + currentPortionValue.details[i];
            otherLongTermDebtValue.details[i] = totalFundingValue - currentPortionValue?.details?.['gestPeriod'];
          }

          additonalMachineValue.aggregate[1] = parseFloat(data.data[0].startupCost.whichMonth) <= 12 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[2] = parseFloat(data.data[0].startupCost.whichMonth) > 12 && parseFloat(data.data[0].startupCost.whichMonth) <= 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[3] = parseFloat(data.data[0].startupCost.whichMonth) > 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;

          for (let i = 1; i <= 3; i++) {
            // totalNetGSTPaidValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]);
            salesCommission.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.commission?.aggregate[i]) || 0), 0);
            transportTotal.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) + (parseFloat(product.transport?.aggregate[i]) || 0), 0);
            totalNetGSTPaidValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) - (i === 1 ? parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST) : 0);
            totalSales.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]);
            let subsidy = i == 1 ? subsidyYear1 : i == 2 ? subsidyYear2 : subsidyYear3;
            totalInflowValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i]) + parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i]) + subsidy;
            totalnetGSTOnSale.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST);
            totalVariable.aggregate[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST) + parseFloat(totalnetGSTOnSale.aggregate[i]);
            totalGrossProfit.aggregate[i] = parseFloat(totalSales.aggregate[i]) - (parseFloat(totalVariable?.aggregate[i]) + parseFloat(commission?.aggregate[i]) + parseFloat(fgTransport?.aggregate[i])) - parseFloat(obj?.aggregate[i]);
            let taxRate = i == 1 ? taxRateYear1 : i == 2 ? taxRateYear2 : taxRateYear3;
            subTotalVariable.aggregate[i] = parseFloat(productValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[i]) + parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) + parseFloat(totalNetGSTPaidValue.aggregate[i]) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].commission) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].transport) + parseFloat(cred.aggregate[i]);
            repaymentValue.aggregate[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.aggregate.principle[i])
            totalTaxPaid.aggregate[i] = (parseFloat(totalGrossProfit.aggregate[i]) + parseFloat(other.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100 < 0 ? 0 : (parseFloat(totalGrossProfit.aggregate[i]) + parseFloat(other.aggregate[i]) - parseFloat(totalFixedCost.aggregate[i])) * taxRate / 100
            inputCreditClaim.aggregate[i] = parseFloat(productValue?.salesProjection?.totalMonthlyValues?.aggregate?.[i]?.variableCostGST) - parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i]) + (i > 1 ? parseFloat(inputCreditClaim.aggregate[i - 1]) : 0);
            incometaxPaidValue.aggregate[i] = totalTaxPaid.aggregate[i];

          }

          for (let i = 1; i <= 3; i++) {
            totalOutflowValue.aggregate[i] = subTotalVariable.aggregate[i] + totalCash.aggregate[i] + totalTaxPaid.aggregate[i] + repaymentValue.aggregate[i] + parseFloat(additonalMachineValue.aggregate[i]);
            openingBalanceValue.aggregate[i] = (i == 1 ? openingBalanceValue.details[1] : parseFloat(closingBalanceValue.aggregate[i - 1]));
            closingBalanceValue.aggregate[i] = parseFloat(openingBalanceValue.aggregate[i]) + (parseFloat(totalInflowValue.aggregate[i]) - parseFloat(totalOutflowValue.aggregate[i]));
            netFixedAssetsValue.aggregate[i] = (i == 1 ? netFixedAssetsValue.details[12] : netFixedAssetsValue.aggregate[i - 1] - nonCash.aggregate[i] + additonalMachineValue.aggregate[i]);
            prepaidExpensesValue.aggregate[i] = (i == 1 ? prepaidExpensesValue.details[12] : prepaidExpensesValue.aggregate[i - 1] + parseFloat(totalCash.aggregate[i - 1]) - parseFloat(cashInterest.aggregate[i - 1]) - parseFloat(cashExcludingInterest.aggregate[i - 1]));
            accruedExpensesValue.aggregate[i] = (i == 1 ? accruedExpensesValue.details[12] : accruedExpensesValue.aggregate[i - 1] + parseFloat(cashExcludingInterest.aggregate[i - 1]) + parseFloat(cashInterest.aggregate[i - 1]) - parseFloat(totalCash.aggregate[i - 1]));
            accountsReceivableValue.aggregate[i] = i > 1 ? (parseFloat(accountsReceivableValue.aggregate[i - 1]) + parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i])) : parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i]);
            gstReceivableValue.aggregate[i] = i > 1 ? (parseFloat(gstReceivableValue.aggregate[i - 1]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i])) : parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i]);
            // totalCurrentAssetsValue.aggregate[i] =  prepaidExpensesValue.aggregate[i] + closingBalanceValue.aggregate[i] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets);
            materialInventoryValue.aggregate[i] = (i <= 1 ? materialInventoryValue?.details?.[12] : i === 2 ? materialInventoryValue?.details?.[12] : i === 3 ? materialInventoryValue.aggregate[i - 1] : 0) + accountsPayableCalc.aggregate[i] - prodQuantityValue.aggregate[i];
            finishedGoodsInventoryValue.aggregate[i] = (i <= 1 ? finishedGoodsInventoryValue?.details?.[12] : i === 3 ? finishedGoodsInventoryValue.aggregate[i - 1] : i === 2 ? finishedGoodsInventoryValue.details[12] : 0) + prodQuantityValue.aggregate[i] - productValue?.salesProjection?.totalMonthlyValues?.aggregate?.[i]?.variableCostB4GST - productValue?.salesProjection?.totalMonthlyValues?.aggregate?.[i]?.variableCostGST;
            totalCurrentAssetsValue.aggregate[i] = prepaidExpensesValue.aggregate[i] + closingBalanceValue.aggregate[i] + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(inputCreditClaim.aggregate[i]) + parseFloat(accountsReceivable.aggregate[i]) + parseFloat(gstReceivableValue.aggregate[i]) + (i === 1 ? materialInventoryValue?.details?.[12] : parseFloat(materialInventoryValue.aggregate[i])) + (i === 1 ? finishedGoodsInventoryValue?.details[12] : parseFloat(finishedGoodsInventoryValue.aggregate[i]));
            totalAssetsValue.aggregate[i] = netFixedAssetsValue.aggregate[i] + totalCurrentAssetsValue.aggregate[i];
            netGSTPayableValue.aggregate[i] = (i == 1 ? netGSTPayableValue.details[12] : netGSTPayableValue.aggregate[i - 1] + totalnetGSTOnSale.aggregate[i] - totalNetGSTPaidValue.aggregate[i]);
            incomeTaxValue.aggregate[i] = (i == 1 ? incomeTaxValue.details[12] : incomeTaxValue.aggregate[i - 1] + incometaxPaidValue.aggregate[i] - totalTaxPaid.aggregate[i]);
            totalCurrentLiabilitiesValue.aggregate[i] = (i == 1 ? totalCurrentLiabilitiesValue.details[12] : netGSTPayableValue.aggregate[i] + incomeTaxValue.aggregate[i]);
            equityCapitalValue.aggregate[i] = equityCapitalValue.details['gestPeriod'];
            profitAfterTaxValue.aggregate[i] = ((totalGrossProfit.aggregate[i] - totalFixedCost.aggregate[i]) - incometaxPaidValue.aggregate[i]);
            retainedEarningsValue.aggregate[i] = i > 1 ? retainedEarningsValue.aggregate[i - 1] + profitAfterTaxValue.aggregate[i] + other.aggregate[i] : retainedEarningsValue.details[12];
            accountsPayableValue.aggregate[i] = i > 1 ? accountsPayableValue?.aggregate?.[i - 1] + accountsPayableCalc?.aggregate[i] - parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST[i]) - parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid[i]) + parseFloat(obj?.aggregate[i]) - parseFloat(obj2?.aggregate[i]) : accountsPayableValue.details[12];
            currentPortionValue.aggregate[i] =
              i === 3 ? 0 :
                (parseFloat(data?.data?.[0]?.sourcesOfFund?.bankFinancial?.aggregate?.principle[i + 1] ?? 0) +
                  parseFloat(data?.data?.[0]?.sourcesOfFund?.familyFriends?.aggregate?.principle[i + 1] ?? 0) +
                  parseFloat(data?.data?.[0]?.sourcesOfFund?.machinarySuppliers?.aggregate?.principle[i + 1] ?? 0) +
                  parseFloat(data?.data?.[0]?.sourcesOfFund?.rawMaterialSup?.aggregate?.principle[i + 1] ?? 0) +
                  parseFloat(data?.data?.[0]?.sourcesOfFund?.mfi?.aggregate?.principle[i + 1] ?? 0) +
                  parseFloat(data?.data?.[0]?.sourcesOfFund?.others?.aggregate?.principle[i + 1] ?? 0));
            totalCurrentLiabilitiesValue.aggregate[i] = accountsPayableValue.aggregate[i] + incomeTaxValue.aggregate[i] + currentPortionValue.aggregate[i]
            otherLongTermDebtValue.aggregate[i] = i === 1 ? (otherLongTermDebtValue.details[11] - currentPortionValue?.aggregate[i]) : (otherLongTermDebtValue.aggregate[i - 1] - currentPortionValue.aggregate[i]);
          }
          inventoryTurnoverValue.aggregate[1] = totalVariableCost?.aggregate[1] / (
            (
              Object.values(materialInventoryValue?.details)
                .filter((item) => !item.gestPeriod)
                ?.reduce((sum, total) => sum + total, 0) +
              Object.values(finishedGoodsInventoryValue?.details)
                ?.filter((item) => !item.gestPeriod)
                ?.reduce((sum, total) => sum + total, 0)
            ) / 12
          )          // +Object.values(finishedGoodsInventoryValue?.details?.reduce((sum, item)=>sum+item,0)))/12)
          inventoryTurnoverValue.aggregate[2] = totalVariableCost?.aggregate[1] / ((materialInventoryValue?.details[12] + finishedGoodsInventoryValue?.details[12] + materialInventoryValue.aggregate[2] + finishedGoodsInventoryValue.aggregate[2]) / 2)
          inventoryTurnoverValue.aggregate[3] = totalVariableCost?.aggregate[2] / ((materialInventoryValue?.aggregate[2] + finishedGoodsInventoryValue?.aggregate[2] + materialInventoryValue.aggregate[3] + finishedGoodsInventoryValue.aggregate[3]) / 2)

          setTotalInflow(totalInflowValue);
          setTotalNetGSTPaid(totalNetGSTPaidValue);
          setTotalSalesRevenue(totalSales);
          setNetGSTOnSale(totalnetGSTOnSale);
          setTotalVariableCost(totalVariable);
          setGrossProfit(totalGrossProfit);
          // setTaxPaid(incometaxPaidValue);
          setTaxPaid(totalTaxPaid);
          setAdditonalMachine(additonalMachineValue);
          setSubTotalVariableCost(subTotalVariable);
          setRepayment(repaymentValue);
          setTotalOutflow(totalOutflowValue);
          setOpeningBalance(openingBalanceValue);
          setClosingBalance(closingBalanceValue);
          //balancesheet 
          setNetFixedAssets(netFixedAssetsValue);
          setPrepaidExpenses(prepaidExpensesValue);
          setAccruedExpenses(accruedExpensesValue);
          setAccountsReceivable(accountsReceivableValue);
          setGstReceivable(gstReceivableValue);
          setTotalCurrentAssets(totalCurrentAssetsValue);
          setTotalAssets(totalAssetsValue);
          setNetGSTPayable(netGSTPayableValue);
          setIncomeTax(incomeTaxValue);
          setTotalCurrentLiabilities(totalCurrentLiabilitiesValue);
          setOtherLongTermDebt(otherLongTermDebtValue);
          setEquityCapital(equityCapitalValue);
          setProfitAfterTax(profitAfterTaxValue);
          setRetainedEarnings(retainedEarningsValue);
          setInputCredit(inputCreditClaim);
          setAccountsPayable(accountsPayableValue);
          setCurrentPortion(currentPortionValue);
          setMaterialInventory(materialInventoryValue);
          setFinishedGoodsInventory(finishedGoodsInventoryValue);
          setInventoryTurnover(inventoryTurnoverValue);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const udapteNarrative = (data) => {
    console.log(data)
    setNarrativeContent(data);
    handleClose();
    // let userId = JSON.parse(localStorage.getItem("user_data")).user._id;
    // axios
    //   .put(`${ApiUrl}center/update/${centerData._id}`, data)
    //   .then((response) => {
    //     getAllcenter();
    //     toast("Updated Successfully", {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //     toast("Error While Updating", {
    //       position: "top-right",
    //       autoClose: 5000,
    //       hideProgressBar: false,
    //       closeOnClick: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       progress: undefined
    //     });
    //   });
  };

  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }

  return (
    <>
      <div>
        <button type="button" className="btn btn-primary float-end mx-sm-1" onClick={() => editNarrativeHandler()}> Edit </button>
        <button type="button" className="btn btn-primary float-end mx-sm-1" onClick={handlePrint}> Print </button>
      </div>

      <div className="narrative-page" id="printarea" ref={componentRef}>
        <div className="page-1 page-break content-size">
          <div className="busines-title text-center">
            <h3><strong>{businessData?.BusinessName}</strong></h3>
            <h4><strong>Business Plan</strong></h4>
          </div>


          <div id="footer">
            <div className="text-left">
              <div><span>{data?.name}</span></div>
              {data?.address.split('\n').map(str => <div>{str}</div>)}
              <div>{data?.phone}</div>
            </div>
            <div className="text-right"><span className="narrative-title">Date</span> : <span>{moment().utc().format("DD MMM YYYY")}</span></div>

            <h5 className="text-center mt-5"><strong>Developed using I Create Biz Plan Tool</strong></h5>
          </div>
        </div>
        <div className="page-2 page-break content-size" >
          <div className="text-center">
            <h4><strong>Table of Contents</strong></h4>
          </div>
          <ol type="1">
            <li>Executive Summary</li>
            <li>Business Idea and Model</li>
            <li>Market Analysis & Marketing Strategy</li>
            <li>Sales & Supply Chain</li>
            <li>Project Cost and Sources </li>
            <li>Human Resources</li>
            <li>Fixed Costs</li>
            <li>Pricing</li>
            <li>Quality, Capacity and USP</li>
            <li>Proforma Financials</li>
            <ol type="a">
              <li>Income Statement & details</li>
              <li>Cashflow & details</li>
              <li>Balance Sheet</li>
              <li>Ratio Analysis</li>
            </ol>
          </ol>
        </div>
        <ol>
          <div className="content-list page-2 page-break" >
            <li><h4><strong><u>Executive Summary</u></strong></h4>
              <p>{narrativeContent?.executiveSummary1} {businessData?.BusinessName}. {narrativeContent?.executiveSummary2} {businessData?.businessIdea}. {narrativeContent?.executiveSummary3} {businessData?.organizationStructure}
              </p>
              <p>{narrativeContent?.executiveSummary4} ₹{getNumericFormat(startupCostData?.totalFundsRequired)} {narrativeContent?.executiveSummary5}</p>
              <ul type="disc">
                {sourcesOfFundData?.fieldLineItemsName.map(function (object, i) {
                  if (object?.Amount) {
                    return <li id={i}>{object?.Description}: ₹{getNumericFormat(object?.Amount)}</li>
                  }
                })}
                <br />
                <p><strong>Total Sources:	₹{getNumericFormat(sourcesOfFundData?.totalAmount)}</strong></p>
              </ul>
              <p><strong><u>Key Numbers</u></strong></p>
              <p>{narrativeContent?.keyNumbers}</p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th className="text-center md-width">Description</th>
                      <th className="text-center md-width">Unit of Measure</th>
                      <th className="text-center md-width">Year 1</th>
                      <th className="text-center md-width">Year 2</th>
                      <th className="text-center md-width">Year 3</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">

                    <tr>
                      <td>Sales Revenue</td>
                      <td>Rs</td>
                      <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[1])}</td>
                      <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[2])}</td>
                      <td className="text-right">{getNumericFormat(totalSalesRevenue.aggregate[3])}</td>
                    </tr>
                    <tr>
                      <td>Profit Before Tax</td>
                      <td>Rs</td>
                      <td className="text-right">{getNumericFormat(grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] + otherIncome.aggregate[1])}</td>
                      <td className="text-right">{getNumericFormat(grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] + otherIncome.aggregate[2])}</td>
                      <td className="text-right">{getNumericFormat(grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] + otherIncome.aggregate[3])}</td>
                    </tr>
                    <tr>
                      <td>Profit After Tax</td>
                      <td>Rs</td>
                      <td className="text-right">{getNumericFormat((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1]) - taxPaid.aggregate[1] + otherIncome.aggregate[1])}</td>
                      <td className="text-right">{getNumericFormat((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2]) - taxPaid.aggregate[2] + otherIncome.aggregate[1])}</td>
                      <td className="text-right">{getNumericFormat((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3]) - taxPaid.aggregate[3] + otherIncome.aggregate[1])}</td>
                    </tr>
                    <tr>
                      <td>Break Even Level</td>
                      <td>Rs</td>
                      <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[1] / (grossProfit.aggregate[1] / totalSalesRevenue.aggregate[1])))}</td>
                      <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[2] / (grossProfit.aggregate[2] / totalSalesRevenue.aggregate[2])))}</td>
                      <td className="text-right">{getNumericFormat((totalFixedExpense.aggregate[3] / (grossProfit.aggregate[3] / totalSalesRevenue.aggregate[3])))}</td>
                    </tr>
                    <tr>
                      <td>DSCR</td>
                      <td>Number</td>
                      <td className="text-right">{((profitAfterTax.aggregate[1] + fixedCostNonCash.aggregate[1] + fixedCostExcludingInterest.aggregate[1] + otherIncome.aggregate[1]) / (fixedCostExcludingInterest.aggregate[1] + currentPortion.details[1]))?.toFixed(2)}</td>
                      <td className="text-right">{((profitAfterTax.aggregate[2] + fixedCostNonCash.aggregate[2] + fixedCostExcludingInterest.aggregate[2] + otherIncome.aggregate[2]) / (fixedCostExcludingInterest.aggregate[2] + currentPortion.details[1]))?.toFixed(2)}</td>
                      <td className="text-right">{((profitAfterTax.aggregate[3] + fixedCostNonCash.aggregate[3] + fixedCostExcludingInterest.aggregate[3] + otherIncome.aggregate[3]) / (fixedCostExcludingInterest.aggregate[3] + currentPortion.details[1]))?.toFixed(2)}</td>
                    </tr>
                    <tr>
                      <td>ROI</td>
                      <td>Percentage</td>
                      <td className="text-right">{((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] + otherIncome.aggregate[1]) / totalAssets.aggregate[1] * 100).toFixed(0)}%</td>
                      <td className="text-right">{((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] + otherIncome.aggregate[2]) / totalAssets.aggregate[2] * 100).toFixed(0)}%</td>
                      <td className="text-right">{((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] + otherIncome.aggregate[3]) / totalAssets.aggregate[3] * 100).toFixed(0)}%</td>
                    </tr>
                    <tr>
                      <td>ROE</td>
                      <td>Percentage</td>
                      <td className="text-right">{((grossProfit.aggregate[1] - totalFixedExpense.aggregate[1] + otherIncome.aggregate[1]) / (retainedEarnings?.aggregate[1] + equityCapital.aggregate[1]) * 100).toFixed(0)}%</td>
                      <td className="text-right">{((grossProfit.aggregate[2] - totalFixedExpense.aggregate[2] + otherIncome.aggregate[2]) / (retainedEarnings?.aggregate[2] + equityCapital.aggregate[2]) * 100).toFixed(0)}%</td>
                      <td className="text-right">{((grossProfit.aggregate[3] - totalFixedExpense.aggregate[3] + otherIncome.aggregate[3]) / (retainedEarnings?.aggregate[3] + equityCapital.aggregate[3]) * 100).toFixed(0)}%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </li>
          </div>
          <div className="content-list page-3 page-break" >
            <li> <h4><strong><u>Business Idea and Model</u></strong></h4>
              <p>{businessData?.businessModelBriefly}</p>
              <p><strong>Equipment</strong></p>
              <p>{narrativeContent?.equipment1} Automatic Machine, Packing Machine. {narrativeContent?.equipment2} directly from Manufactures</p>
              <p><strong>Material</strong></p>
              <p>{narrativeContent?.material1} {productData?.rawMaterialConsumption?.rawMaterial?.map((object, i, { length }) => {
                if (object?.raw_material_description) {
                  return <span>{object?.raw_material_description}{length - 1 === i ? "." : ", "}</span>
                }
              })} {narrativeContent?.material2}</p>
              <p><strong>Human Resource</strong></p>
              <p>{narrativeContent?.humanResource}</p>
              <p><strong>Utilities</strong></p>
              <p>{narrativeContent?.utilities}</p>
              <p><strong>Other:</strong></p>
              <p>{narrativeContent?.other}</p>
            </li>
          </div>
          <div className="content-list page-4" >
            <li>
              <h4><strong><u>Market Analysis & Marketing Strategy</u></strong></h4>
              <p><strong>Customer:</strong></p>
              <p>{narrativeContent?.customer1} {businessData?.likelyAggregateDemand}. {narrativeContent?.customer2} {businessData?.noOfCustomers} {narrativeContent?.customer3}</p>
              <p><strong>Competition</strong></p>
              <p>{narrativeContent?.competition1} {businessData?.competitorInLocality} {narrativeContent?.competition2} {businessData?.strengths} {narrativeContent?.competition3} {businessData?.shortcome} {narrativeContent?.competition4}</p>
              <p><strong>Marketing Strategy</strong></p>
              <p>{narrativeContent?.strategy}</p>
            </li>
          </div>
          <div className="content-list page-5 page-break" >
            <li><h4><strong><u>Sales & Supply Chain</u></strong></h4>
              <p>{narrativeContent?.salesAndSupply} {businessData?.businessIdea}</p>
              <p>{narrativeContent?.salesAndSupply1} {businessData?.address}</p>
              <p>{narrativeContent?.salesAndSupply2}</p>
              <p>{narrativeContent?.salesAndSupply3}</p>

              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th className="text-center md-width">{businessData?.businessIdea}</th>
                      <th className="text-center md-width">Year 1</th>
                      <th className="text-center md-width">Year 2</th>
                      <th className="text-center md-width">Year 3</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">

                    {productData?.salesProjection?.fieldProductSkuName?.map((object) => {
                      return <><tr>
                        <td>{object?.productId} – {object?.name}</td>
                        <td className="text-right">{getNumericFormat(object?.aggregate[1].quantity)}</td>
                        <td className="text-right">{getNumericFormat(object?.aggregate[2].quantity)}</td>
                        <td className="text-right">{getNumericFormat(object?.aggregate[3].quantity)}</td>
                      </tr>

                      </>
                    })}
                    {productData?.productList?.map(product => {
                      Year1 = Year1 + parseFloat(product?.basicPlannedProduction?.aggregate[1].usage);
                      Year2 = Year2 + parseFloat(product?.basicPlannedProduction?.aggregate[2].usage);
                      Year3 = Year3 + parseFloat(product?.basicPlannedProduction?.aggregate[3].usage);
                    })
                    }

                    {
                      (Year1 || Year2 || Year3) ?
                        <tr>
                          <td>Basic Product</td>
                          <td className="text-right">{getNumericFormat(Year1)}</td>
                          <td className="text-right">{getNumericFormat(Year2)}</td>
                          <td className="text-right">{getNumericFormat(Year3)}</td>
                        </tr> : ""
                    }



                  </tbody>
                </table>
              </div>
              <p>{narrativeContent?.salesAndSupply4}</p>
            </li>
          </div>
          <div className="content-list page-6 page-break" >
            <li><h4><strong><u>Project Cost & Sources of Funds</u></strong></h4>
              <p>{narrativeContent?.sourcesOfFund1} {getNumericFormat(sourcesOfFundData?.totalAmount)}. {narrativeContent?.sourcesOfFund2}</p>
              <div><strong>Sources</strong></div>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th className="text-center sm-width">SL. No.</th>
                      <th className="text-center md-width">Source</th>
                      <th className="text-center md-width">Amount ₹ </th>
                      <th className="text-center sm-width">Interest <br />Rate per annum</th>
                      <th className="text-center md-width">Term of Loan (yrs)</th>
                      <th className="text-center md-width">Moratorium in months</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">

                    {sourcesOfFundData?.fieldLineItemsName.map(function (object, i) {
                      if (object?.Amount) {
                        return <tr>
                          <td className="text-center">{i + 1}</td>
                          <td>{object?.Description}</td>
                          <td className="text-right">{parseFloat(object?.Amount) ? getNumericFormat(object?.Amount) : ""}</td>
                          <td className="text-right">{parseFloat(object['Interest/Service Fee']) ? parseFloat(object['Interest/Service Fee']) + "%" : ""}</td>
                          <td className="text-right">{parseFloat(object['Repayment Terms (Months)']) ? parseFloat(object['Repayment Terms (Months)']) : ""}</td>
                          <td className="text-right">{parseFloat(object['Moratorium in months']) ? parseFloat(object['Moratorium in months']) : ""}</td>
                        </tr>
                      }
                    })}
                    <tr>
                      <td></td>
                      <td>Total</td>
                      <td className="text-right">{getNumericFormat(sourcesOfFundData?.totalAmount)}</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>

                  </tbody>
                </table>
              </div>
              <p><strong>Human Resources</strong></p>
              <p>{narrativeContent?.sourcesOfFund3}</p>
            </li>
          </div>
          <div className="content-list page-7" >
            <li><h4><strong><u>Fixed Cost</u></strong></h4>
              <p>{narrativeContent?.fixedCost}</p>
            </li>
          </div>
          <div className="content-list page-8" >
            <li> <h4><strong><u>Pricing</u></strong></h4>
              <p>{narrativeContent?.pricing1}</p>
              <ul type="disc">
                {productData?.salesProjection?.fieldProductSkuName?.map((object) => {
                  return <li>{object?.productId} – {object?.name} {narrativeContent?.pricing2} ₹{getNumericFormat(object?.sales_price)} </li>
                })}
              </ul>
              <br />
              <p>{narrativeContent?.pricing3}</p>
            </li>

          </div>
          <div className="content-list page-9" >
            <li>
              <h4><strong><u>Quality and USP</u></strong></h4>
              <p>{narrativeContent?.qualityAndUSP}</p>
            </li>
          </div>
          <div className="content-list page-10" >
            <li>
              <h4><strong><u>Risk Factor & Risk Mitigation</u></strong></h4>
              <p>{narrativeContent?.risk}</p>
            </li>
          </div>

          <div className="content-list page-11 page-break" >
            <li>
              <h4><strong><u>Proforma Financials</u></strong></h4>
              <p><strong>Unit Economics</strong></p>
              <p>{narrativeContent?.unitEconomics}</p>
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead className="table-light">
                    <tr>
                      <th className="text-center md-width">Description</th>
                      <th className="text-center md-width">Sale Price</th>
                      <th className="text-center md-width">Variable Cost per unit</th>
                      <th className="text-center md-width">Gross Profit per Unit</th>
                    </tr>
                  </thead>
                  <tbody className="table-border-bottom-0">
                    {productData?.salesProjection?.fieldProductSkuName?.map((object, i) => {
                      const skuData = productData?.salesProjection?.fieldProductSkuName?.find(productName => productName.name === object.sku_description);
                      return <tr>
                        <td>{object?.productId} – {object?.name}</td>
                        <td className="text-right">{getNumericFormat(object?.sales_price)}</td>
                        {productData?.productList?.map(product => {
                          if (product?.skuData) {
                            const skuRes = product?.skuData?.find((res) => res.sku_description === object?.name);

                            return skuRes ? <> <td className="text-right md-width">{skuRes?.sku_rmValue !== "" ? getNumericFormat(skuRes?.sku_rmValue) : "-"}</td>
                              <td className="text-right md-width">{skuRes?.sku_gross_profit !== "" ? getNumericFormat(skuRes?.sku_gross_profit) : "-"}</td>
                            </> : '';

                          } else {
                            return <><td className="text-center md-width">-</td><td className="text-center md-width">-</td></>
                          }
                        })
                        }

                      </tr>
                    })}
                    {/* {productData?.productList?.map(product => {
                    
                      return <>{product?.skuData?.map((object) => {
                        const salesData = productData?.salesProjection?.fieldProductSkuName?.find(productName => productName.name === object.sku_description);

                        console.log(salesData)
                        return <tr>
                          <td>{product?.product_name} – {object?.sku_description}</td>                         
                          <td>{getNumericFormat(salesData?.sales_price)}</td>
                          <td className="text-center md-width">{object?.sku_rmValue !== "" ? getNumericFormat(object?.sku_rmValue) : "-"}</td>
                          <td className="text-center md-width">{object?.sku_gross_profit !== ""? getNumericFormat(object?.sku_gross_profit) : "-"}</td>
                        </tr>
                      })}
                      </>
                    })}                 */}
                  </tbody>
                </table>
              </div>
            </li>
          </div>
          <div className="page-12 page-break" >
            <h4><strong><u>Proforma Financials</u></strong></h4>
            <ol type="1">
              <li>Reports</li>
              <ol type="a">
                <li>Income Statement</li>
                <ol type="i">
                  <li>Sales Revenue</li>
                  <li>Variable Cost</li>
                  <li>Fixed Cost</li>
                  <li>Unit Economics</li>
                  <li>Production Plan</li>
                </ol>
                <li>Cash Flow</li>
                <ol type="i">
                  <li>Repayment</li>
                </ol>
                <li>Balance Sheet</li>
                <li>Ratio Analysis</li>
              </ol>
            </ol>
          </div>
        </ol>
        <div className="page-break">
        </div>
        <div className="page-break print-hide table-transform cashFlow-print">
          <CashFlow />
        </div>
        <div className="page-break print-hide table-transform repayment-print">
          <RepaymentBackUp />
        </div>
        <div class="page-break print-hide table-transform">
          <StartUpCostReport />
        </div>
        <div className="page-break print-hide table-transform income-statement-print">
          <IncomeStatement />
        </div>
        <div className="page-break print-hide table-transform income-sale-print">
          <IncomeSalesRevenue />
        </div>
        <div className="page-break print-hide table-transform income-fixed-print">
          <IncomeFixedCost />
        </div>
        <div className="page-break print-hide table-transform income-prod-print">
          <IncomeProdPlan />
        </div>
        <div class="print-hide page-break table-transform">
          <IncomeUnitEconomics />
        </div>
        <div className="print-hide page-break table-transform balance-sheet-print">
        <BalanceSheet />
        </div>
        <div class="print-hide page-break table-transform">
        <RatioAnalysis />
        </div>
      </div>
      <CustomModal
        show={show}
        handleClose={handleClose}
        title="Update Narrative"
        data={narrativeContent}
        isModalXl="true"
        submitBtnTitle="Save"
        cancelBtnTitle="Close"
        udapteNarrative={udapteNarrative}
        Body={editNarrative}
      ></CustomModal>
    </>
  );
}

export default Narrative;
