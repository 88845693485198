import ProtégéCard from "screens/protégé/components/common/protégéCard";
import {Link} from "react-router-dom";
import {useLocation, useNavigate, useParams} from "react-router";
import {useEffect, useState} from "react";
import Layout from "common/components/layout";
import axios from "axios";
import {ApiUrl} from "API/config";
import moment from "moment";
import Loader from "common/components/loader";
function ManageProtégé(props) {
  const operation = useParams().op;
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [data, setData] = useState();
  const [businessData, setBusinessData] = useState();
  const [manufacturingData, setManufacturingData] = useState({});
  const [capacityData, setCapacityData] = useState({});
  const [manpowerData, setManpowerData] = useState({});
  const [startupCostData, setStartupCostData] = useState({});
  const [sourcesOfFundData, setSourcesOfFundData] = useState({});
  const [fixedCostData, setFixedCostData] = useState({});
  const [tradingData, setTradingData] = useState({});
  const [agroData, setAgroData] = useState({});
  const [servicesData, setServicesData] = useState({});
  const [animalHusbandryData, setAnimalHusbandryData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setManufacturingData(data.data[0].manufacturingIds);
          setTradingData(data.data[0].trading);
          setServicesData(data.data[0].service);
          setAnimalHusbandryData(data.data[0].animalhusbandry);
          setAgroData(data.data[0].agro);
          setData(data.data[0].personalInfoId);
          setBusinessData(data.data[0].businessInfoid);
          setCapacityData(data.data[0].capacity);
          setManpowerData(data.data[0].manpower);
          setStartupCostData(data.data[0].startupCost);
          setSourcesOfFundData(data.data[0].sourcesOfFund);
          setFixedCostData(data.data[0].fixedCost);
          setIsLoader(false);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  const toFixedIfNecessary = (value, dp) => {
    return +parseFloat(value).toFixed(dp);
  };

  const getStatus = (percentage) => {
    let status;
    if (percentage == 0) {
      return (status = "Not Started");
    } else if (percentage < 100) {
      return (status = "In Progress");
    } else if (percentage == 100) {
      return (status = "Completed");
    }
  };
  let tile1Status = getStatus(data && data.progressScore ? data && data.progressScore : 0);
  let tiles = [
    {
      progress: data && data.progressScore ? toFixedIfNecessary(data && data.progressScore, 2) + "%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      title: "Personal Information",
      id: location.state && location.state.id,
      status: `${tile1Status}`,
      route: "PersonalInformation",
    },
    {
      progress: businessData && businessData.progressScore ? businessData && toFixedIfNecessary(businessData.progressScore, 2) + "%" : "0%",
      lastEditedOn:
        businessData && businessData.updated
          ? moment(businessData && businessData.updated)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: businessData && businessData.userId ? businessData && businessData.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      title: "Business Idea and Model",
      status: `${getStatus(businessData && businessData.progressScore ? businessData && businessData.progressScore : 0)}`,
      personalInfoPorgressScore: data && data.progressScore,
      route: "BusinessIdeaModel",
    },
  ];
  let commonTiles = [
    {
      progress: capacityData && capacityData._id ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      capacityId: capacityData  && capacityData._id,
      title: "Notes",
      status: capacityData ? "COMPLETED" : "Not Started",
      route: "Capacity",
    },
    {
      progress: startupCostData && startupCostData._id ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      startupCostId: startupCostData && startupCostData._id,
      title: "Startup Cost",
      status: startupCostData ? "COMPLETED" : "Not Started",
      route: "StartupCost",
    },
    {
      progress: manpowerData && manpowerData._id ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      manpowerId: manpowerData && manpowerData._id,
      title: "Manpower",
      status: manpowerData ? "COMPLETED" : "Not Started",
      route: "Manpower",
    },
    {
      progress: sourcesOfFundData && sourcesOfFundData._id ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      sourcesOfFundId: sourcesOfFundData && sourcesOfFundData._id,
      title: "Sources Of Fund",
      status: sourcesOfFundData && sourcesOfFundData._id ? "COMPLETED" : "Not Started",
      route: "SourcesOfFund",
    },
    {
      progress: fixedCostData && fixedCostData._id ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      fixedCostId: fixedCostData && fixedCostData._id,
      title: "Fixed Cost",
      status: fixedCostData && fixedCostData._id ? "COMPLETED" : "Not Started",
      route: "FixedCost",
    },
  ];
  const ManuFacturingTile = [
    {
      progress: manufacturingData && manufacturingData.salesProjection ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: manufacturingData && manufacturingData._id,
      businessType: "manufacturing",
      salesProjectionId: manufacturingData && manufacturingData.salesProjection && manufacturingData.salesProjection._id,
      title: "Sales Plan",
      status: manufacturingData && manufacturingData.salesProjection ? "COMPLETED" : "Not Started",
      route: "SalesProjection",
    },
    {
      progress: manufacturingData && manufacturingData.rawMaterialConsumption ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: manufacturingData && manufacturingData._id,
      businessType: "manufacturing",
      rawMaterialConsumptionId: manufacturingData && manufacturingData.rawMaterialConsumption && manufacturingData.rawMaterialConsumption._id,
      title: "Basic Input Details",
      status: manufacturingData && manufacturingData.rawMaterialConsumption ? "COMPLETED" : "Not Started",
      route: "RawMaterialConsumption",
    },

    {
      progress: manufacturingData && manufacturingData.orderingFrequency ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessType: "manufacturing",
      businessId: manufacturingData && manufacturingData._id,
      protegeId: params.id,
      orderingFrequencyId: manufacturingData && manufacturingData.orderingFrequency && manufacturingData.orderingFrequency._id,
      title: "Ordering Frequency",
      status: manufacturingData && manufacturingData.orderingFrequency ? "COMPLETED" : "Not Started",
      route: "OrderingFrequency",
    },
    {
      progress: manufacturingData && manufacturingData.rawMaterialProcurement ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: manufacturingData && manufacturingData._id,
      businessType: "manufacturing",
      protegeId: params.id,
      rawMaterialProcurementId: manufacturingData && manufacturingData.rawMaterialProcurement && manufacturingData.rawMaterialProcurement._id,
      title: "Input Procurement",
      status: manufacturingData && manufacturingData.rawMaterialProcurement ? "COMPLETED" : "Not Started",
      route: "RawMaterialProcurement",
    },
  ];

  const TradingTitles = [
    {
      progress: tradingData && tradingData.salesProjection ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: tradingData && tradingData._id,
      businessType: "trading",
      salesProjectionId: tradingData && tradingData.salesProjection && tradingData.salesProjection._id,
      title: "Sales Plan",
      status: tradingData && tradingData.salesProjection ? "COMPLETED" : "Not Started",
      route: "SalesProjection/Trading",
    },
    {
      progress: tradingData && tradingData.rawMaterialConsumption ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: tradingData && tradingData._id,
      businessType: "trading",
      rawMaterialConsumptionId: tradingData && tradingData.rawMaterialConsumption && tradingData.rawMaterialConsumption._id,
      title: "Basic Input Details",
      status: tradingData && tradingData.rawMaterialConsumption ? "COMPLETED" : "Not Started",
      route: "RawMaterialConsumption/Trading",
    },

    {
      progress: tradingData && tradingData.orderingFrequency ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: tradingData && tradingData._id,
      businessType: "trading",
      protegeId: params.id,
      orderingFrequencyId: tradingData && tradingData.orderingFrequency && tradingData.orderingFrequency._id,
      title: "Ordering Frequency",
      status: tradingData && tradingData.orderingFrequency ? "COMPLETED" : "Not Started",
      route: "OrderingFrequency/Trading",
    },
    {
      progress: tradingData && tradingData.rawMaterialProcurement ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: tradingData && tradingData._id,
      businessType: "trading",
      rawMaterialProcurementId: tradingData && tradingData.rawMaterialProcurement && tradingData.rawMaterialProcurement._id,
      title: "Input Procurement",
      status: tradingData && tradingData.rawMaterialProcurement ? "COMPLETED" : "Not Started",
      route: "RawMaterialProcurement/Trading",
    },
  ];

  const ServicesTitles = [
    {
      progress: servicesData && servicesData.salesProjection ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: servicesData && servicesData._id,
      businessType: "service",
      protegeId: params.id,
      salesProjectionId: servicesData && servicesData.salesProjection && servicesData.salesProjection._id,
      title: "Sales Plan",
      status: servicesData && servicesData.salesProjection ? "COMPLETED" : "Not Started",
      route: "SalesProjection/Services",
    },
    {
      progress: servicesData && servicesData.rawMaterialConsumption ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: servicesData && servicesData._id,
      businessType: "service",
      protegeId: params.id,
      rawMaterialConsumptionId: servicesData && servicesData.rawMaterialConsumption && servicesData.rawMaterialConsumption._id,
      title: "Basic Input Details",
      status: servicesData && servicesData.rawMaterialConsumption ? "COMPLETED" : "Not Started",
      route: "RawMaterialConsumption/Services",
    },

    {
      progress: servicesData && servicesData.orderingFrequency ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: servicesData && servicesData._id,
      businessType: "service",
      protegeId: params.id,
      orderingFrequencyId: servicesData && servicesData.orderingFrequency && servicesData.orderingFrequency._id,
      title: "Ordering Frequency",
      status: servicesData && servicesData.orderingFrequency ? "COMPLETED" : "Not Started",
      route: "OrderingFrequency/Services",
    },
    {
      progress: servicesData && servicesData.rawMaterialProcurement ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: servicesData && servicesData._id,
      businessType: "service",
      protegeId: params.id,
      rawMaterialProcurementId: servicesData && servicesData.rawMaterialProcurement && servicesData.rawMaterialProcurement._id,
      title: "Input Procurement",
      status: servicesData && servicesData.rawMaterialProcurement ? "COMPLETED" : "Not Started",
      route: "RawMaterialProcurement/Services",
    },
  ];
  const AnimalHusbandryTiles = [
    {
      progress: animalHusbandryData && animalHusbandryData.salesProjection ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: animalHusbandryData && animalHusbandryData._id,
      businessType: "animalhusbandry",
      salesProjectionId: animalHusbandryData && animalHusbandryData.salesProjection && animalHusbandryData.salesProjection._id,
      title: "Sales Plan",
      status: animalHusbandryData && animalHusbandryData.salesProjection ? "COMPLETED" : "Not Started",
      route: "SalesProjection/AnimalHusbandry",
    },    
    {
      progress: animalHusbandryData && animalHusbandryData.rawMaterialConsumption ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: animalHusbandryData && animalHusbandryData._id,
      businessType: "animalhusbandry",
      protegeId: params.id,
      rawMaterialConsumptionId: animalHusbandryData && animalHusbandryData.rawMaterialConsumption && animalHusbandryData.rawMaterialConsumption._id,
      title: "Basic Input Details",
      status: animalHusbandryData && animalHusbandryData.rawMaterialConsumption ? "COMPLETED" : "Not Started",
      route: "RawMaterialConsumption/AnimalHusbandry",
    },
    {
      progress: animalHusbandryData && animalHusbandryData.orderingFrequency ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: animalHusbandryData && animalHusbandryData._id,
      protegeId: params.id,
      businessType: "animalhusbandry",
      orderingFrequencyId: animalHusbandryData && animalHusbandryData.orderingFrequency && animalHusbandryData.orderingFrequency._id,
      title: "Ordering Frequency",
      status: animalHusbandryData && animalHusbandryData.orderingFrequency ? "COMPLETED" : "Not Started",
      route: "OrderingFrequency/AnimalHusbandry",
    },
    {
      progress: animalHusbandryData && animalHusbandryData.rawMaterialProcurement ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      businessId: animalHusbandryData && animalHusbandryData._id,
      businessType: "animalhusbandry",
      protegeId: params.id,
      rawMaterialProcurementId: animalHusbandryData && animalHusbandryData.rawMaterialProcurement && animalHusbandryData.rawMaterialProcurement._id,
      title: "Input Procurement",
      status: animalHusbandryData && animalHusbandryData.rawMaterialProcurement ? "COMPLETED" : "Not Started",
      route: "RawMaterialProcurement/AnimalHusbandry",
    },
  ];
  const agroTiles = [
    {
      progress: agroData && agroData.salesProjection ? "100%" : "0%",
      lastEditedOn:
        data && data.updatedDate
          ? moment(data && data.updatedDate)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
      protegeId: params.id,
      businessId: agroData && agroData._id,
      businessType: "agro",
      salesProjectionId: agroData && agroData.salesProjection && agroData.salesProjection._id,
      title: "Sales Plan",
      status: agroData && agroData.salesProjection ? "COMPLETED" : "Not Started",
      route: "SalesProjection/Agro",
    },  
    {
      progress: agroData && agroData.rawMaterialConsumption ? "100%" : "0%",
      lastEditedOn:
        agroData && agroData.updated
          ? moment(agroData && agroData.updated)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
     businessId: agroData && agroData._id,
      businessType: "agro",
      protegeId: params.id,
      rawMaterialConsumptionId: agroData && agroData.rawMaterialConsumption && agroData.rawMaterialConsumption._id,
      title: "Basic Input Details",
      status: agroData && agroData.rawMaterialConsumption ? "COMPLETED" : "Not Started",
      route: "RawMaterialConsumption/Agro",
    },

    {
      progress: agroData && agroData.orderingFrequency ? "100%" : "0%",
      lastEditedOn:
        agroData && agroData.updated
          ? moment(agroData && agroData.updated)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
     businessId: agroData && agroData._id,
      businessType: "agro",
      protegeId: params.id,
      orderingFrequencyId: agroData && agroData.orderingFrequency && agroData.orderingFrequency._id,
      title: "Ordering Frequency",
      status: agroData && agroData.orderingFrequency ? "COMPLETED" : "Not Started",
      route: "OrderingFrequency/Agro",
    },
    {
      progress: agroData && agroData.rawMaterialProcurement ? "100%" : "0%",
      lastEditedOn:
        agroData && agroData.updated
          ? moment(agroData && agroData.updated)
              .utc()
              .format("DD-MM-YYYY")
          : "N/A",
      lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
      id: params.id,
     businessId: agroData && agroData._id,
      businessType: "agro",
      protegeId: params.id,
      rawMaterialProcurementId: agroData && agroData.rawMaterialProcurement && agroData.rawMaterialProcurement._id,
      title: "Input Procurement",
      status: agroData && agroData.rawMaterialProcurement ? "COMPLETED" : "Not Started",
      route: "RawMaterialProcurement/Agro",
    },
  ];

  const getTypeData = () => {
    return (
      businessData &&
      businessData.typeOfBusiness &&
      businessData.typeOfBusiness.map((x) => {
        return {
          progress: x.name == "Animal Husbandry" ? (animalHusbandryData ? "100%" : "0%") : x.name == "Trading" ? (tradingData ? "100%" : "0%") : x.name == "Agro" ? (agroData ? "100%" : "0%") : x.name == "Service" ? (servicesData ? "100%" : "0%") : manufacturingData ? "100%" : "0%",
          lastEditedOn:
            data && data.updatedDate
              ? moment(data && data.updatedDate)
                  .utc()
                  .format("DD-MM-YYYY")
              : "N/A",
          lastEditedBy: data && data.userId ? data && data.userId.name : "NA",
          id: params.id,
          title: x?.name,
          status: manufacturingData ? "COMPLETED" : agroData ? "COMPLETED" : animalHusbandryData ? "COMPLETED" :  tradingData ? "COMPLETED" : servicesData ? "COMPLETED" : "Not Started",
          route: x?.name?.replaceAll(" ", ""),
        };
      })
    );
  };
  
  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }

  return (
    <Layout>
      {" "}
      <div className="row">
        <div className="col-6">
          <h4 className="fw-bold py-3 mb-4 cursor-pointer">
            <span className="text-muted fw-light" onClick={() => navigate(-1)}>
              Protégé /
            </span>{" "}
            {operation} Protégé
          </h4>
        </div>
        {/* <div className="col-6">
          <span className="float-end text-primary mt-3">1 of 14 completed</span>
        </div> */}
      </div>
      <div className="row">
        {tiles.map((tile, index) => (
          <ProtégéCard tile={tile} key={index} operation={operation}></ProtégéCard>
        ))}
      </div>
      <hr className="my-5" />
      <div className="row">
        {commonTiles.map((tile, index) => (
          <ProtégéCard tile={tile} key={index} operation={operation}></ProtégéCard>
        ))}
      </div>
      {getTypeData() && getTypeData().find((y) => y.title == "Manufacturing") && (
        <>
          <hr className="my-5" />
          <h3>Manufacturing</h3>
          <div className="row">
            {getTypeData() &&
              getTypeData()
                .filter((y) => y.title == "Manufacturing")
                .map((X, i) => {
                  return <>{X.title != "Manufacturing" && X.title != "Trading" && X.title != "Service" ? <ProtégéCard tile={X} disbaled={true} operation={operation} key={i}></ProtégéCard> : <ProtégéCard tile={X} disbaled={false} operation={operation} key={i}></ProtégéCard>}</>;
                })}
            {businessData &&
              businessData.typeOfBusiness &&
              businessData.typeOfBusiness.find((z) => z.name == "Manufacturing") &&
              ManuFacturingTile.map((x, i) => {
                return <ProtégéCard tile={x} disbaled={false} operation={operation} key={i}></ProtégéCard>;
              })}
          </div>
        </>
      )}
      {getTypeData() && getTypeData().find((y) => y.title == "Trading") && (
        <>
          <hr className="my-5" />
          <h3>Trading</h3>
          <div className="row">
            {getTypeData() &&
              getTypeData()
                .filter((y) => y.title == "Trading")
                .map((X, i) => {
                  return <>{X.title != "Manufacturing" && X.title != "Trading" ? <ProtégéCard tile={X} disbaled={true} operation={operation} key={i}></ProtégéCard> : <ProtégéCard tile={X} disbaled={false} operation={operation} key={i}></ProtégéCard>}</>;
                })}
            {businessData &&
              businessData.typeOfBusiness &&
              businessData.typeOfBusiness.find((z) => z.name == "Trading") &&
              TradingTitles.map((x, i) => {
                return <ProtégéCard tile={x} disbaled={false} operation={operation} key={i}></ProtégéCard>;
              })}
          </div>
        </>
      )}
      {getTypeData() && getTypeData().find((y) => y.title == "Agro") && (
        <>
          <hr className="my-5" />
          <h3>Agro</h3>
          <div className="row">
            {getTypeData() &&
              getTypeData()
                .filter((y) => y.title == "Agro")
                .map((X, i) => {
                  return <>{X.title != "Manufacturing" && X.title != "Service" && X.title != "Agro" ? <ProtégéCard tile={X} disbaled={true} operation={operation} key={i}></ProtégéCard> : <ProtégéCard tile={X} disbaled={false} operation={operation} key={i}></ProtégéCard>}</>;
                })}
            {businessData &&
              businessData.typeOfBusiness &&
              businessData.typeOfBusiness.find((z) => z.name == "Agro") &&
              agroTiles.map((x, i) => {
                return <ProtégéCard tile={x} disbaled={false} operation={operation} key={i}></ProtégéCard>;
              })}
          </div>
        </>
      )}
      {getTypeData() && getTypeData().find((y) => y.title == "Service") && (
        <>
          <hr className="my-5" />
          <h3>Service</h3>
          <div className="row">
            {getTypeData() &&
              getTypeData()
                .filter((y) => y.title == "Service")
                .map((X, i) => {
                  return <>{X.title != "Manufacturing" && X.title != "Service" ? <ProtégéCard tile={X} disbaled={true} operation={operation} key={i}></ProtégéCard> : <ProtégéCard tile={X} disbaled={false} operation={operation} key={i}></ProtégéCard>}</>;
                })}
            {businessData &&
              businessData.typeOfBusiness &&
              businessData.typeOfBusiness.find((z) => z.name == "Service") &&
              ServicesTitles.map((x, i) => {
                return <ProtégéCard tile={x} disbaled={false} operation={operation} key={i}></ProtégéCard>;
              })}
          </div>
        </>
      )}
      {getTypeData() && getTypeData().find((y) => y.title == "Animal Husbandry") && (
        <>
          <hr className="my-5" />
          <h3>Animal Husbandry</h3>
          <div className="row">
            {getTypeData() &&
              getTypeData()
                .filter((y) => y.title == "Animal Husbandry")
                .map((X, i) => {
                  return <>{X.title != "Manufacturing" && X.title != "Animal Husbandry" ? <ProtégéCard tile={X} disbaled={true} operation={operation} key={i}></ProtégéCard> : <ProtégéCard tile={X} disbaled={false} operation={operation} key={i}></ProtégéCard>}</>;
                })}
            {businessData &&
              businessData.typeOfBusiness &&
              businessData.typeOfBusiness.find((z) => z.name == "Animal Husbandry") &&
              AnimalHusbandryTiles.map((x, i) => {
                return <ProtégéCard tile={x} disbaled={false} operation={operation} key={i}></ProtégéCard>;
              })}
          </div>
        </>
      )}
      <hr className="my-5" />
      <div className="content-backdrop fade"></div>
    </Layout>
  );
}

export default ManageProtégé;
