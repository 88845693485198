import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
function EditNarrative(props) {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  return (
    <>
      <ToastContainer />
      <div className="card-body">
        <div className="col-md-12">
          <form
            onSubmit={handleSubmit((data) => props.props.udapteNarrative(data))}
          >
            <div className="row">
              <div className="col-sm-3">
                <label htmlFor="executiveSummary1" className="form-label required">
                  Executive Summary 1
                </label>
                <textarea
                  type="text"
                  id="executiveSummary1"
                  className={`form-control ${errors.executiveSummary1 ? "errorBorder" : ""}`}
                  placeholder="Enter Executive Summary"
                  defaultValue={props.props.data.executiveSummary1}
                  {...register("executiveSummary1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="executiveSummary2" className="form-label required">
                  Executive Summary 2
                </label>
                <textarea
                  type="text"
                  id="executiveSummary2"
                  className={`form-control ${errors.executiveSummary2 ? "errorBorder" : ""}`}
                  placeholder="Enter Executive Summary"
                  defaultValue={props.props.data.executiveSummary2}
                  {...register("executiveSummary2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="executiveSummary3" className="form-label required">
                  Executive Summary 3
                </label>
                <textarea
                  type="text"
                  id="executiveSummary3"
                  className={`form-control ${errors.executiveSummary3 ? "errorBorder" : ""}`}
                  placeholder="Enter Executive Summary"
                  defaultValue={props.props.data.executiveSummary3}
                  {...register("executiveSummary3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="executiveSummary4" className="form-label required">
                  Executive Summary 4
                </label>
                <textarea
                  type="text"
                  id="executiveSummary4"
                  className={`form-control ${errors.executiveSummary4 ? "errorBorder" : ""}`}
                  placeholder="Enter Executive Summary"
                  defaultValue={props.props.data.executiveSummary4}
                  {...register("executiveSummary4", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="executiveSummary5" className="form-label required">
                  Executive Summary 5
                </label>
                <textarea
                  type="text"
                  id="executiveSummary5"
                  className={`form-control ${errors.executiveSummary5 ? "errorBorder" : ""}`}
                  placeholder="Enter Executive Summary"
                  defaultValue={props.props.data.executiveSummary5}
                  {...register("executiveSummary5", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="keyNumbers" className="form-label required">
                Key Numbers
                </label>
                <textarea
                  type="text"
                  id="keyNumbers"
                  className={`form-control ${errors.keyNumbers ? "errorBorder" : ""}`}
                  placeholder="Enter Key Numbers"
                  defaultValue={props.props.data.keyNumbers}
                  {...register("keyNumbers", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="equipment1" className="form-label required">
                Equipment 1
                </label>
                <textarea
                  type="text"
                  id="equipment1"
                  className={`form-control ${errors.equipment1 ? "errorBorder" : ""}`}
                  placeholder="Enter equipment"
                  defaultValue={props.props.data.equipment1}
                  {...register("equipment1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="equipment2" className="form-label required">
                Equipment 2
                </label>
                <textarea
                  type="text"
                  id="equipment2"
                  className={`form-control ${errors.equipment2 ? "errorBorder" : ""}`}
                  placeholder="Enter equipment 2"
                  defaultValue={props.props.data.equipment2}
                  {...register("equipment2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="material1" className="form-label required">
                  Material 1
                </label>
                <textarea
                  type="text"
                  id="material1"
                  className={`form-control ${errors.material1 ? "errorBorder" : ""}`}
                  placeholder="Enter Material"
                  defaultValue={props.props.data.material1}
                  {...register("material1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="material2" className="form-label required">
                  Material 2
                </label>
                <textarea
                  type="text"
                  id="material2"
                  className={`form-control ${errors.material2 ? "errorBorder" : ""}`}
                  placeholder="Enter Material"
                  defaultValue={props.props.data.material2}
                  {...register("material2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="material3" className="form-label required">
                  Material 3
                </label>
                <textarea
                  type="text"
                  id="material3"
                  className={`form-control ${errors.material3 ? "errorBorder" : ""}`}
                  placeholder="Enter Material"
                  defaultValue={props.props.data.material3}
                  {...register("material3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="humanResource" className="form-label required">
                Human Resource
                </label>
                <textarea
                  type="text"
                  id="humanResource"
                  className={`form-control ${errors.humanResource ? "errorBorder" : ""}`}
                  placeholder="Enter Human Resource"
                  rows="3"
                  defaultValue={props.props.data.humanResource}
                  {...register("humanResource", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="utilities" className="form-label required">
                Utilities
                </label>
                <textarea
                  type="text"
                  id="utilities"
                  className={`form-control ${errors.utilities ? "errorBorder" : ""}`}
                  placeholder="Enter Utilities" rows="3"
                  defaultValue={props.props.data.utilities}
                  {...register("utilities", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="other" className="form-label required">
                Other
                </label>
                <textarea
                  type="text"
                  id="other"
                  className={`form-control ${errors.other ? "errorBorder" : ""}`}
                  placeholder="Enter Other" rows="3"
                  defaultValue={props.props.data.other}
                  {...register("other", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>

              <div className="col-sm-3">
                <label htmlFor="customer1" className="form-label required">
                  Customer 1
                </label>
                <textarea
                  type="text"
                  id="customer1"
                  className={`form-control ${errors.customer1 ? "errorBorder" : ""}`}
                  placeholder="Enter Customer"
                  defaultValue={props.props.data.customer1}
                  {...register("customer1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="customer2" className="form-label required">
                 Customer 2
                </label>
                <textarea
                  type="text"
                  id="customer2"
                  className={`form-control ${errors.customer2 ? "errorBorder" : ""}`}
                  placeholder="Enter Customer"
                  defaultValue={props.props.data.customer2}
                  {...register("customer2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="customer3" className="form-label required">
                  Customer 3
                </label>
                <textarea
                  type="text"
                  id="customer3"
                  className={`form-control ${errors.customer3 ? "errorBorder" : ""}`}
                  placeholder="Enter Customer" rows="3"
                  defaultValue={props.props.data.customer3}
                  {...register("customer3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="competition1" className="form-label required">
                  Competition 1
                </label>
                <textarea
                  type="text"
                  id="competition1"
                  className={`form-control ${errors.competition1 ? "errorBorder" : ""}`}
                  placeholder="Enter Competition"
                  defaultValue={props.props.data.competition1}
                  {...register("competition1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="competition2" className="form-label required">
                  Competition 2
                </label>
                <textarea
                  type="text"
                  id="competition2"
                  className={`form-control ${errors.competition2 ? "errorBorder" : ""}`}
                  placeholder="Enter Competition"
                  defaultValue={props.props.data.competition2}
                  {...register("competition2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="competition3" className="form-label required">
                  Competition 3
                </label>
                <textarea
                  type="text"
                  id="competition3"
                  className={`form-control ${errors.competition3 ? "errorBorder" : ""}`}
                  placeholder="Enter Competition"
                  defaultValue={props.props.data.competition3}
                  {...register("competition3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="competition4" className="form-label required">
                  Competition 4
                </label>
                <textarea
                  type="text"
                  id="competition4"
                  className={`form-control ${errors.competition4 ? "errorBorder" : ""}`}
                  placeholder="Enter Competition"
                  defaultValue={props.props.data.competition4}
                  {...register("competition4", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="strategy" className="form-label required">
                Marketing Strategy
                </label>
                <textarea
                  type="text"
                  id="strategy"
                  className={`form-control ${errors.strategy ? "errorBorder" : ""}`}
                  placeholder="Enter Strategy"
                  rows="2"
                  defaultValue={props.props.data.strategy}
                  {...register("strategy", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="salesAndSupply1" className="form-label required">
                  Sales And Supply 1
                </label>
                <textarea
                  type="text"
                  id="salesAndSupply1"
                  className={`form-control ${errors.salesAndSupply1 ? "errorBorder" : ""}`}
                  placeholder="Enter Sales And Supply"
                  defaultValue={props.props.data.salesAndSupply1}
                  {...register("salesAndSupply1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="salesAndSupply2" className="form-label required">
                  Sales And Supply 2
                </label>
                <textarea
                  type="text"
                  id="salesAndSupply2"
                  className={`form-control ${errors.salesAndSupply2 ? "errorBorder" : ""}`}
                  placeholder="Enter Sales And Supply"
                  rows="5"
                  defaultValue={props.props.data.salesAndSupply2}
                  {...register("salesAndSupply2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="salesAndSupply3" className="form-label required">
                  Sales And Supply 3
                </label>
                <textarea
                  type="text"
                  id="salesAndSupply3"
                  className={`form-control ${errors.salesAndSupply3 ? "errorBorder" : ""}`}
                  placeholder="Enter Sales And Supply"
                  defaultValue={props.props.data.salesAndSupply3}
                  {...register("salesAndSupply3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="salesAndSupply4" className="form-label required">
                  Sales And Supply 4
                </label>
                <textarea
                  type="text"
                  id="salesAndSupply4"
                  className={`form-control ${errors.salesAndSupply4 ? "errorBorder" : ""}`}
                  placeholder="Enter Sales And Supply"
                  defaultValue={props.props.data.salesAndSupply4}
                  {...register("salesAndSupply4", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="salesAndSupply5" className="form-label required">
                  Sales And Supply 5
                </label>
                <textarea
                  type="text"
                  id="salesAndSupply5"
                  className={`form-control ${errors.salesAndSupply5 ? "errorBorder" : ""}`}
                  placeholder="Enter Sales And Supply" rows="4"
                  defaultValue={props.props.data.salesAndSupply5}
                  {...register("salesAndSupply5", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              
              <div className="col-sm-3">
                <label htmlFor="sourcesOfFund1" className="form-label required">
                  Sources Of Fund 1
                </label>
                <textarea
                  type="text"
                  id="sourcesOfFund1"
                  className={`form-control ${errors.sourcesOfFund1 ? "errorBorder" : ""}`}
                  placeholder="Enter Sources Of Fund" rows="4"
                  defaultValue={props.props.data.sourcesOfFund1}
                  {...register("sourcesOfFund1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="sourcesOfFund2" className="form-label required">
                  Sources Of Fund 2
                </label>
                <textarea
                  type="text"
                  id="sourcesOfFund2"
                  className={`form-control ${errors.sourcesOfFund2 ? "errorBorder" : ""}`}
                  placeholder="Enter Sources Of Fund" rows="4"
                  defaultValue={props.props.data.sourcesOfFund2}
                  {...register("sourcesOfFund2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="sourcesOfFund3" className="form-label required">
                  Sources Of Fund 3
                </label>
                <textarea
                  type="text"
                  id="sourcesOfFund3"
                  className={`form-control ${errors.sourcesOfFund3 ? "errorBorder" : ""}`}
                  placeholder="Enter Sources Of Fund" rows="3"
                  defaultValue={props.props.data.sourcesOfFund3}
                  {...register("sourcesOfFund3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="fixedCost" className="form-label required">
                Fixed Cost
                </label>
                <textarea
                  type="text"
                  id="fixedCost"
                  className={`form-control ${errors.fixedCost ? "errorBorder" : ""}`}
                  placeholder="Enter Fixed Cost"
                  defaultValue={props.props.data.fixedCost}
                  {...register("fixedCost", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="pricing1" className="form-label required">
                Pricing 1
                </label>
                <textarea
                  type="text"
                  id="pricing1"
                  className={`form-control ${errors.pricing1 ? "errorBorder" : ""}`}
                  placeholder="Enter Pricing 1"
                  defaultValue={props.props.data.pricing1}
                  {...register("pricing1", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="pricing2" className="form-label required">
                Pricing 2
                </label>
                <textarea
                  type="text"
                  id="pricing2"
                  className={`form-control ${errors.pricing2 ? "errorBorder" : ""}`}
                  placeholder="Enter Pricing 2"
                  defaultValue={props.props.data.pricing2}
                  {...register("pricing2", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-3">
                <label htmlFor="pricing3" className="form-label required">
                Pricing 3
                </label>
                <textarea
                  type="text"
                  id="pricing3"
                  className={`form-control ${errors.pricing3 ? "errorBorder" : ""}`}
                  placeholder="Enter Pricing 3" rows="3"
                  defaultValue={props.props.data.pricing3}
                  {...register("pricing3", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="qualityAndUSP" className="form-label required">
                Quality And USP
                </label>
                <textarea
                  type="text"
                  id="qualityAndUSP"
                  className={`form-control ${errors.qualityAndUSP ? "errorBorder" : ""}`}
                  placeholder="Enter Quality And USP" rows="3"
                  defaultValue={props.props.data.qualityAndUSP}
                  {...register("qualityAndUSP", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="risk" className="form-label required">
                Risk
                </label>
                <textarea
                  type="text"
                  id="risk"
                  className={`form-control ${errors.risk ? "errorBorder" : ""}`}
                  placeholder="Enter Risk" rows="3"
                  defaultValue={props.props.data.risk}
                  {...register("risk", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
              <div className="col-sm-6">
                <label htmlFor="unitEconomics" className="form-label required">
                Unit Economics
                </label>
                <textarea
                  type="text"
                  id="unitEconomics"
                  className={`form-control ${errors.unitEconomics ? "errorBorder" : ""}`}
                  placeholder="Enter Unit Economics" rows="2"
                  defaultValue={props.props.data.unitEconomics}
                  {...register("unitEconomics", { required: false })}
                />
                {errors.name && <span className="textRed"></span>}
              </div>
            </div>

            <div className="row pt-3">
              <div className="col-sm-12 text-center">
                <button type="submit" className="btn btn-md btn-primary me-2">Save</button>
                <button onClick={() => props.props.handleClose()} className="btn btn-md btn-secondary btn-outline-secondary">Cancel</button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </>

  );
}

export default EditNarrative;