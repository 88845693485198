import { useLocation, useNavigate, useParams } from "react-router";
import React, { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";

function IncomeProdPlan(props) {
  const operation = useParams().op;
  const params = useParams();
  const [isLoader, setIsLoader] = useState(false);
  const [productData, setProductData] = useState();
  const [productList, setProductList] = useState();
  const [productSkuList, setProductSkuList] = useState();
  const [totalSkus, setTotalSKUs] = useState();
  const [totalProducts, setTotalProducts] = useState();
  const [noOfHours, setNoOfHours] = useState();
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };

  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setIsLoader(false);
          
            let productValue;
            let businessTypes=[];
            if (data.data[0].manufacturingIds) {
              businessTypes.push(data.data[0].manufacturingIds)
            } 
             if (data.data[0].trading) {
              businessTypes.push(data.data[0].trading)
            } 
             if (data.data[0].service) {
              businessTypes.push(data.data[0].service)
            } 
             if (data.data[0].animalhusbandry) {
              businessTypes.push(data.data[0].animalhusbandry)
            } 
             if (data.data[0].agro) {
              businessTypes.push(data.data[0].agro)
            } 
            console.log(businessTypes)
          
            const combinedValue = {
              orderingFrequency: { fieldOrderingFrequencyName: [] },
              productList: [],
              rawMaterialConsumption: { rawMaterial: [] },
              rawMaterialProcurement: {
                fieldProductRMName:[],
                initialInputCost:0,
                initialInputCostGST:0,
                monthlyInputGSTPaid:{ 
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                monthlyInputPurchaseB4GST:{
                   1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                yearlyInputGSTPaid:{ 
                   1: 0,
                  2: 0,
                  3: 0,
                },
                yearlyInputPurchaseB4GST:{ 
                   1: 0,
                  2: 0,
                  3: 0,
                } 
              },
              salesProjection: {
                fieldProductSkuName:[],
                fieldProductsName:[],
                totalMonthlyCashInflowOfSales:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                totalYearlyCashInFlowOfSales:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
                totalMonthlyCashInflowOfSalesGST:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                totalYearlyCashInFlowOfSalesGST:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
                totalMonthlyGSTCollectOnSale:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                totalYearlyGSTCollectOnSale:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
                totalMonthlySalesRevenue:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                },
                totalYearlySalesRevenue:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
                totalMonthlyValues:{
                  details:{
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    4: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    5: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    6: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    7: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    8: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    9: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    10: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    11: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    12: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  
                  }, 
                  aggregate:{
                    1: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    2: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                    3: {
                      quantity: 0,
                      prod_quantity: 0,
                      closing_Stock: 0,
                      salesRevenue: 0,
                      commission: 0,
                      transport: 0,
                      variableCostB4GST: 0,
                      variableCostGST: 0,
                      gst_collected_on_sales: 0,
                    },
                  }
  
                },
                creditCardFee:{
                  details:{
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                    9: 0,
                    10: 0,
                    11: 0,
                    12: 0,
                  }, 
                  aggregate:{
                    1: 0,
                    2: 0,
                    3: 0,
                  },
                },
                creditCardFeeCashInFlow:{
                  details:{
                    1: 0,
                    2: 0,
                    3: 0,
                    4: 0,
                    5: 0,
                    6: 0,
                    7: 0,
                    8: 0,
                    9: 0,
                    10: 0,
                    11: 0,
                    12: 0,
                  }, 
                  aggregate:{
                    1: 0,
                    2: 0,
                    3: 0,
                  },
                }
  
              },
            };
            
            
            for (let type = 0; type < businessTypes.length; type++) {
              console.log(type);
              console.log(businessTypes[type])
              //orderFrequency
              combinedValue.orderingFrequency.fieldOrderingFrequencyName = [
                ...(combinedValue?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
                ...(businessTypes?.[type]?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
              ];
              console.log(businessTypes[type])
  
              //productList
              combinedValue.productList = [...(combinedValue?.productList), ...businessTypes[type]?.productList];
              console.log(businessTypes[type])
  
              //consumption
              combinedValue.rawMaterialConsumption.rawMaterial = [
                ...(combinedValue?.rawMaterialConsumption?.rawMaterial ?? []),
                ...(businessTypes[type].rawMaterialConsumption?.rawMaterial ?? []),
              ];
              console.log(businessTypes[type])
  
              //procurement
              combinedValue.rawMaterialProcurement.fieldProductRMName = [
                ...(combinedValue?.rawMaterialProcurement?.fieldProductRMName ?? []),
                ...(businessTypes[type].rawMaterialProcurement?.fieldProductRMName ?? []),
              ];
              combinedValue.rawMaterialProcurement.initialInputCost += businessTypes[type].rawMaterialProcurement?.initialInputCost?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCost):0;
              combinedValue.rawMaterialProcurement.initialInputCostGST += businessTypes[type].rawMaterialProcurement?.initialInputCostGST?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCostGST):0;
              for(let month=1; month<=12;month++){
                  combinedValue.rawMaterialProcurement.monthlyInputGSTPaid[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]):0;
                  combinedValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]):0;
              }
              console.log('this')
              for(let year=1; year<=3;year++){
                  combinedValue.rawMaterialProcurement.yearlyInputGSTPaid[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]):0;
                  combinedValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]):0;
              }
              console.log(businessTypes[type])
  
              //salesProjection
              combinedValue.salesProjection.fieldProductSkuName = [
                ...(combinedValue?.salesProjection?.fieldProductSkuName ?? []),
                ...(businessTypes[type].salesProjection?.fieldProductSkuName ?? []),
              ];
              combinedValue.salesProjection.fieldProductsName = [
                ...(combinedValue?.salesProjection?.fieldProductsName ?? []),
                ...(businessTypes[type].salesProjection?.fieldProductsName ?? []),
              ];
  
              for(let month=1; month<=12;month++){
                combinedValue.salesProjection.totalMonthlyCashInflowOfSales[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]):0;
                combinedValue.salesProjection.totalMonthlyCashInflowOfSalesGST[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]):0;
                combinedValue.salesProjection.totalMonthlyGSTCollectOnSale[month] += businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]):0;
                combinedValue.salesProjection.totalMonthlySalesRevenue[month] += businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].commission += businessTypes[type].salesProjection?.totalMonthlyValues?.details?.[month]?.commission?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].commission):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].transport += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST):0;
                combinedValue.salesProjection.totalMonthlyValues.details[month].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales):0;
                combinedValue.salesProjection.creditCardFee.details[month] += businessTypes[type].salesProjection?.creditCardFee?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.details[month]):0;
                combinedValue.salesProjection.creditCardFeeCashInFlow.details[month] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.details[month]):0;
              }
              for(let year=1; year<=3;year++){
                combinedValue.salesProjection.totalYearlyCashInFlowOfSales[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year]) : 0;
                combinedValue.salesProjection.totalYearlyCashInFlowOfSalesGST[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year]) : 0;
                combinedValue.salesProjection.totalYearlyGSTCollectOnSale[year] += businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year]) : 0;
                combinedValue.salesProjection.totalYearlySalesRevenue[year] += businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year]) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].commission += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].transport += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST) : 0;
                combinedValue.salesProjection.totalMonthlyValues.aggregate[year].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales) : 0;
                combinedValue.salesProjection.creditCardFee.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFee?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.aggregate[year]):0;
                combinedValue.salesProjection.creditCardFeeCashInFlow.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.aggregate[year]):0;
             
              }
              console.log(type, combinedValue)
  
            }
            productValue= combinedValue;
            console.log(productValue)
            setProductData(productValue);
  
            
          const productList = productValue?.productList;
          setProductList(productList);
          const noOfHours = productList?.map(x=>parseFloat(x?.capacity?.[0]?.installed_capacity))
        setNoOfHours(noOfHours);
        const productSkuList =
            productValue?.salesProjection?.fieldProductSkuName;
          setProductSkuList(productSkuList);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }

  return (
    <>
      <h5>Production Plan And Capacity</h5>
      <div className="table-responsive">
        {productList?.map((item, index) => (
          <table key={index} className="table table-bordered">
            <thead className="table-light">
            <tr>
                <td colSpan={16}className="text-center">
                  {item?.product_name}
                </td>
              </tr>
              <tr>
                <th className="text-center lg-width">Description</th>
                <th className="text-center md-width">Month 1</th>
                <th className="text-center md-width">Month 2</th>
                <th className="text-center md-width">Month 3</th>
                <th className="text-center md-width">Month 4</th>
                <th className="text-center md-width">Month 5</th>
                <th className="text-center md-width">Month 6</th>
                <th className="text-center md-width">Month 7</th>
                <th className="text-center md-width">Month 8</th>
                <th className="text-center md-width">Month 9</th>
                <th className="text-center md-width">Month 10</th>
                <th className="text-center md-width">Month 11</th>
                <th className="text-center md-width">Month 12</th>
                <th className="text-center md-width">Year 1</th>
                <th className="text-center md-width">Year 2</th>
                <th className="text-center md-width">Year 3</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
           
              <tr>
                <td className="text-center lg-width">Basic Product Quantity</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[1]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[2]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[3]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[4]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[5]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[6]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[7]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[8]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[9]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[10]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[11]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[12]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[1]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[2]?.productionPlan))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[3]?.productionPlan))}</td>
                
              </tr>
              <tr>
                <td className="text-center lg-width">Basic Product No. Of Hours</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[1]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[2]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[3]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[4]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[5]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[6]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[7]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[8]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[9]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[10]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[11]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.details?.[12]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[1]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[2]?.productionPlan)/noOfHours?.[index])}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(item?.basicPlannedProduction?.aggregate?.[3]?.productionPlan)/noOfHours?.[index])}</td>
                
              </tr>
              {productSkuList?.filter(prod=>prod?.product_id === item?._id)?.map((prod,index) => (
                <React.Fragment key={prod?.product_id}>
              <tr >
                 <td className="text-center lg-width">{prod?.name}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[1]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[2]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[3]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[4]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[5]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[6]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[7]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[8]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[9]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[10]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[11]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[12]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[1]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[2]?.prod_quantity))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[3]?.prod_quantity))}</td>
              </tr>
               <tr >
                 <td className="text-center lg-width">{prod?.name} No. Of Hours</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[1]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[2]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[3]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[4]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[5]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[6]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[7]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[8]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[9]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[10]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[11]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.details[12]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[1]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[2]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
                <td className="text-center md-width">{getNumericFormat(parseFloat(prod?.aggregate[3]?.prod_quantity)/parseFloat(item?.skuData?.[index]?.sku_capacity_per_hour))}</td>
              </tr>
              </React.Fragment>

              ))}
              
            </tbody>
           
          </table>
        ))}
      </div>
    </>
  );
}

export default IncomeProdPlan;
