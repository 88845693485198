import { useParams } from "react-router";
import { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function CashFlow(props) {
  const operation = useParams().op;
  const params = useParams();
  const [data, setData] = useState();
  const [businessData, setBusinessData] = useState();
  const [productData, setProductData] = useState();
  const [sourcesOfFundData, setSourcesOfFundData] = useState({});
  const [totalInflow, setTotalInflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [startupCostData, setStartupCostData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const [fixedCostCash, setFixedCostCash] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [creditCardFee, setCreditCardFee] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalOutflow, setTotalOutflow] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [additonalMachine, setAdditonalMachine] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [subTotalVariableCost, setSubTotalVariableCost] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalFixedExpense, setTotalFixedExpense] = useState({
    details: {
      gestPeriod: 0,
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalSalesRevenue, setTotalSalesRevenue] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalVariableCost, setTotalVariableCost] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [grossProfit, setGrossProfit] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      gestPeriod:0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [netGSTOnSale, setNetGSTOnSale] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [taxPaid, setTaxPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
      gestPeriod:0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [repayment, setRepayment] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [totalNetGSTPaid, setTotalNetGSTPaid] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [openingBalance, setOpeningBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });
  const [closingBalance, setClosingBalance] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });

  const [outflowFInal, setOutflowFinal] = useState({
    details: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  },
  aggregate: {
    1: 0,
    2: 0,
    3: 0,
  }
});
const [commission, setCommission] = useState({
  details: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  },
  aggregate: {
    1: 0,
    2: 0,
    3: 0,
  }
});
const [fgTransport, setFgTransport] = useState({
  details: {
    1: 0,
    2: 0,
    3: 0,
    4: 0,
    5: 0,
    6: 0,
    7: 0,
    8: 0,
    9: 0,
    10: 0,
    11: 0,
    12: 0,
  },
  aggregate: {
    1: 0,
    2: 0,
    3: 0,
  }
});
  const [otherIncome, setOtherIncome] = useState({
    details: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0,
      7: 0,
      8: 0,
      9: 0,
      10: 0,
      11: 0,
      12: 0,
    },
    aggregate: {
      1: 0,
      2: 0,
      3: 0,
    }
  });


  const [compositionOfSalesCard, setCompositionOfSalesCard] = useState({});
  const [pay, setPay] = useState();
  let userData = localStorage.getItem("user_data");
  let userObj = userData ? JSON.parse(localStorage.getItem("user_data")).user : undefined;
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setData(data.data[0].personalInfoId);
          setBusinessData(data.data[0].businessInfoid);
          setStartupCostData(data.data[0].startupCost ? data.data[0].startupCost : {});
          setSourcesOfFundData(data.data[0].sourcesOfFund ? data.data[0].sourcesOfFund : {});
         
          setIsLoader(false);
          
          let productValue;
          let businessTypes=[];
          if (data.data[0].manufacturingIds) {
            businessTypes.push(data.data[0].manufacturingIds)
          } 
           if (data.data[0].trading) {
            businessTypes.push(data.data[0].trading)
          } 
           if (data.data[0].service) {
            businessTypes.push(data.data[0].service)
          } 
           if (data.data[0].animalhusbandry) {
            businessTypes.push(data.data[0].animalhusbandry)
          } 
           if (data.data[0].agro) {
            businessTypes.push(data.data[0].agro)
          } 
          console.log(businessTypes)
        
          const combinedValue = {
            orderingFrequency: { fieldOrderingFrequencyName: [] },
            productList: [],
            rawMaterialConsumption: { rawMaterial: [] },
            rawMaterialProcurement: {
              fieldProductRMName:[],
              initialInputCost:0,
              initialInputCostGST:0,
              monthlyInputGSTPaid:{ 
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              monthlyInputPurchaseB4GST:{
                 1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              yearlyInputGSTPaid:{ 
                 1: 0,
                2: 0,
                3: 0,
              },
              yearlyInputPurchaseB4GST:{ 
                 1: 0,
                2: 0,
                3: 0,
              } 
            },
            salesProjection: {
              fieldProductSkuName:[],
              fieldProductsName:[],
              totalMonthlyCashInflowOfSales:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSales:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyCashInflowOfSalesGST:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyCashInFlowOfSalesGST:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyGSTCollectOnSale:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlyGSTCollectOnSale:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlySalesRevenue:{
                1: 0,
                2: 0,
                3: 0,
                4: 0,
                5: 0,
                6: 0,
                7: 0,
                8: 0,
                9: 0,
                10: 0,
                11: 0,
                12: 0,
              },
              totalYearlySalesRevenue:{
                1: 0,
                2: 0,
                3: 0,
              },
              totalMonthlyValues:{
                details:{
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  4: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  5: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  6: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  7: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  8: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  9: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  10: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  11: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  12: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                
                }, 
                aggregate:{
                  1: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  2: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                  3: {
                    quantity: 0,
                    prod_quantity: 0,
                    closing_Stock: 0,
                    salesRevenue: 0,
                    commission: 0,
                    transport: 0,
                    variableCostB4GST: 0,
                    variableCostGST: 0,
                    gst_collected_on_sales: 0,
                  },
                }

              },
              creditCardFee:{
                details:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                }, 
                aggregate:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
              },
              creditCardFeeCashInFlow:{
                details:{
                  1: 0,
                  2: 0,
                  3: 0,
                  4: 0,
                  5: 0,
                  6: 0,
                  7: 0,
                  8: 0,
                  9: 0,
                  10: 0,
                  11: 0,
                  12: 0,
                }, 
                aggregate:{
                  1: 0,
                  2: 0,
                  3: 0,
                },
              }

            },
          };
          
          
          for (let type = 0; type < businessTypes.length; type++) {
            console.log(type);
            console.log(businessTypes[type])
            //orderFrequency
            combinedValue.orderingFrequency.fieldOrderingFrequencyName = [
              ...(combinedValue?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
              ...(businessTypes?.[type]?.orderingFrequency?.fieldOrderingFrequencyName ?? []),
            ];
            console.log(businessTypes[type])

            //productList
            combinedValue.productList = [...(combinedValue?.productList), ...businessTypes[type]?.productList];
            console.log(businessTypes[type])

            //consumption
            combinedValue.rawMaterialConsumption.rawMaterial = [
              ...(combinedValue?.rawMaterialConsumption?.rawMaterial ?? []),
              ...(businessTypes[type].rawMaterialConsumption?.rawMaterial ?? []),
            ];
            console.log(businessTypes[type])

            //procurement
            combinedValue.rawMaterialProcurement.fieldProductRMName = [
              ...(combinedValue?.rawMaterialProcurement?.fieldProductRMName ?? []),
              ...(businessTypes[type].rawMaterialProcurement?.fieldProductRMName ?? []),
            ];
            combinedValue.rawMaterialProcurement.initialInputCost += businessTypes[type].rawMaterialProcurement?.initialInputCost?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCost):0;
            combinedValue.rawMaterialProcurement.initialInputCostGST += businessTypes[type].rawMaterialProcurement?.initialInputCostGST?parseFloat(businessTypes[type].rawMaterialProcurement?.initialInputCostGST):0;
            for(let month=1; month<=12;month++){
                combinedValue.rawMaterialProcurement.monthlyInputGSTPaid[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputGSTPaid?.[month]):0;
                combinedValue.rawMaterialProcurement.monthlyInputPurchaseB4GST[month] += businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]?parseFloat(businessTypes[type].rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[month]):0;
            }
            console.log('this')
            for(let year=1; year<=3;year++){
                combinedValue.rawMaterialProcurement.yearlyInputGSTPaid[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputGSTPaid?.[year]):0;
                combinedValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[year] += businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]?parseFloat(businessTypes[type].rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[year]):0;
            }
            console.log(businessTypes[type])

            //salesProjection
            combinedValue.salesProjection.fieldProductSkuName = [
              ...(combinedValue?.salesProjection?.fieldProductSkuName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductSkuName ?? []),
            ];
            combinedValue.salesProjection.fieldProductsName = [
              ...(combinedValue?.salesProjection?.fieldProductsName ?? []),
              ...(businessTypes[type].salesProjection?.fieldProductsName ?? []),
            ];

            for(let month=1; month<=12;month++){
              combinedValue.salesProjection.totalMonthlyCashInflowOfSales[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSales?.[month]):0;
              combinedValue.salesProjection.totalMonthlyCashInflowOfSalesGST[month] += businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyCashInflowOfSalesGST?.[month]):0;
              combinedValue.salesProjection.totalMonthlyGSTCollectOnSale[month] += businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlyGSTCollectOnSale?.[month]):0;
              combinedValue.salesProjection.totalMonthlySalesRevenue[month] += businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]?parseFloat(businessTypes[type].salesProjection?.totalMonthlySalesRevenue?.[month]):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].commission += businessTypes[type].salesProjection?.totalMonthlyValues?.details?.[month]?.commission?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].commission):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].quantity):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].prod_quantity):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].closing_Stock):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].salesRevenue):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].transport += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].transport):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostB4GST):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].variableCostGST):0;
              combinedValue.salesProjection.totalMonthlyValues.details[month].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales?parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.details[month].gst_collected_on_sales):0;
              combinedValue.salesProjection.creditCardFee.details[month] += businessTypes[type].salesProjection?.creditCardFee?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.details[month]):0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.details[month] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.details?.[month]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.details[month]):0;
            }
            for(let year=1; year<=3;year++){
              combinedValue.salesProjection.totalYearlyCashInFlowOfSales[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSales?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyCashInFlowOfSalesGST[year] += businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyCashInFlowOfSalesGST?.[year]) : 0;
              combinedValue.salesProjection.totalYearlyGSTCollectOnSale[year] += businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlyGSTCollectOnSale?.[year]) : 0;
              combinedValue.salesProjection.totalYearlySalesRevenue[year] += businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year] ? parseFloat(businessTypes[type].salesProjection?.totalYearlySalesRevenue?.[year]) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].commission += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].commission) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].prod_quantity += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].prod_quantity) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].closing_Stock += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].closing_Stock) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].salesRevenue += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].salesRevenue) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].transport += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].transport) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostB4GST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostB4GST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].variableCostGST += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].variableCostGST) : 0;
              combinedValue.salesProjection.totalMonthlyValues.aggregate[year].gst_collected_on_sales += businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales ? parseFloat(businessTypes[type].salesProjection?.totalMonthlyValues.aggregate[year].gst_collected_on_sales) : 0;
              combinedValue.salesProjection.creditCardFee.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFee?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFee.aggregate[year]):0;
              combinedValue.salesProjection.creditCardFeeCashInFlow.aggregate[year] += businessTypes[type]?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[year]?parseFloat( businessTypes[type].salesProjection?.creditCardFeeCashInFlow.aggregate[year]):0;
           
            }
            console.log(type, combinedValue)

          }
          
          productValue= combinedValue;
          console.log(productValue)
          setProductData(productValue);

          
          // debugger
          let totalCash = fixedCostCash;
          let totalFixedCost = totalFixedExpense;


          let salesCommission = commission;
          let transportTotal = fgTransport;
          console.log(  data.data[0].fixedCost.fieldLineItemsName)
          data.data[0].fixedCost.fieldLineItemsName.filter((x, index) => {
            totalCash.details['gestPeriod'] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? (x.details['gestPeriod'] !== null && !isNaN(parseFloat(x.details['gestPeriod'])) ? parseFloat(x.details['gestPeriod']) : 0) : 0;
            console.log(totalCash.details['gestPeriod'])
         
            totalFixedCost.details['gestPeriod'] += ( totalFixedCost.details['gestPeriod']===undefined) ? 0 :  totalFixedCost.details['gestPeriod']+isNaN(parseFloat(x.details['gestPeriod']))?0:parseFloat(x.details['gestPeriod']);
            // console.log('Here')
            for (let i = 1; i <= 12; i++) {
              totalCash.details[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? (!isNaN(parseFloat(x.details[i])) ? parseFloat(x.details[i]) : 0) : 0;
              totalFixedCost.details[i] = (totalFixedCost.details[i] !== undefined) ? ((isNaN(parseFloat(totalFixedCost.details[i])) ? 0 : parseFloat(totalFixedCost.details[i])) + (isNaN(parseFloat(x.details[i])) ? 0 : parseFloat(x.details[i]))) : 0;
              console.log(i, x.lineItemName,x.details[i], totalFixedCost.details[i] )
            }
            for (let i = 1; i <= 3; i++) {
              totalCash.aggregate[i] += x.lineItemName !== "Dep + Amort (Non Cash)" && x.lineItemName !== "Addtnl Dep (Non Cash)" ? (!isNaN(parseFloat(x.aggregate[i])) ? parseFloat(x.aggregate[i]) : 0) : 0;
              totalFixedCost.aggregate[i] = (totalFixedCost.aggregate[i] !== undefined) ? ((isNaN(parseFloat(totalFixedCost.aggregate[i])) ? 0 : parseFloat(totalFixedCost.aggregate[i])) + (isNaN(parseFloat(x.aggregate[i])) ? 0 : parseFloat(x.aggregate[i]))) : 0;
              console.log(i, x.lineItemName,x.aggregate[i],totalFixedCost.aggregate[i] )

            }
          
          console.log('Here')
          });
          
          console.log('Here')
                 
          // console.log(totalCash)
   

    

          const whichMonth = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth)?parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMonth):0;
          let other = {
            details: {
              gestPeriod: 0,
              1: 0,
              2: 0,
              3: 0,
              4: 0,
              5: 0,
              6: 0,
              7: 0,
              8: 0,
              9: 0,
              10: 0,
              11: 0,
              12: 0,
            },
            aggregate: {
              1: 0,
              2: 0,
              3: 0,
            }
          };
          if(whichMonth>0){
           
            if(whichMonth<=12){
              other.details[whichMonth] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)? parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine):0;
              other.aggregate[1] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)?parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine):0;
            }else if(whichMonth<=24){
              other.aggregate[2] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)?parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine):0;
            }else if(whichMonth<=36){
              other.aggregate[3] = parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine)?parseFloat(data?.data?.[0]?.sourcesOfFund?.whichMachine):0;

            }
            setOtherIncome(other)
          }
          console.log('other', other)

          setFixedCostCash(totalCash);
          setTotalFixedExpense(totalFixedCost);
          let totalInflowValue = totalInflow;
          let sourcesOfFund = 0;
          data.data[0].sourcesOfFund?.fieldLineItemsName?.map(item => {
            sourcesOfFund += parseFloat(item.Amount)
          })
          totalInflowValue.details['gestPeriod'] = sourcesOfFund
          let subTotalVariable = subTotalVariableCost;
          subTotalVariable.details['gestPeriod'] = parseFloat(data.data[0].startupCost.totalDepreciableAssets) + parseFloat(data.data[0].startupCost.totalNonDepreciableAssets) + parseFloat(data.data[0].startupCost.totalIntangiblesAssets) + parseFloat(data.data[0].startupCost.totalDepositsAndAdvancesAssets) + parseFloat(data.data[0].startupCost.initialRawMaterialPurchase) + parseFloat(totalCash.details['gestPeriod']) + data.data[0].startupCost.initialCash;
          let totalOutflowValue = totalOutflow;
          totalOutflowValue.details['gestPeriod'] =
          (data.data[0].startupCost?.totalDepreciableAssets + data.data[0].startupCost?.totalNonDepreciableAssets+data.data[0].startupCost?.totalIntangiblesAssets +data.data[0].startupCost?.totalDepositsAndAdvancesAssets)+parseFloat(data.data[0].startupCost?.initialRawMaterialPurchase) + totalCash?.details?.gestPeriod ;
          console.log(data.data[0].startupCost?.totalDepreciableAssets , data.data[0].startupCost?.totalNonDepreciableAssets ,data.data[0].startupCost?.totalIntangiblesAssets ,data.data[0].startupCost?.totalDepositsAndAdvancesAssets,parseFloat(data.data[0].startupCost?.initialRawMaterialPurchase) , totalCash?.details?.gestPeriod );
          let repaymentValue = repayment;
          let totalNetGSTPaidValue = totalNetGSTPaid;
          let totalSales = totalSalesRevenue;
          let totalVariable = totalVariableCost;
          let totalnetGSTOnSale = netGSTOnSale;
          let totalGrossProfit = grossProfit;
          let totalTaxPaid = taxPaid;
          let actualTaxPaid = JSON.parse(JSON.stringify(taxPaid));
          let additonalMachineValue = additonalMachine;
          let openingBalanceValue = openingBalance;
          let closingBalanceValue = closingBalance;
          let taxRateYear1 = data.data[0].businessInfoid.taxRateYear1 ? data.data[0].businessInfoid.taxRateYear1 : 0;
          let taxRateYear2 = data.data[0].businessInfoid.taxRateYear2 ? data.data[0].businessInfoid.taxRateYear2 : 0;
          let taxRateYear3 = data.data[0].businessInfoid.taxRateYear3 ? data.data[0].businessInfoid.taxRateYear3 : 0;
           
          // let taxRateYear1 = 6;
          // let taxRateYear2 = 8;
          // let taxRateYear3 = 10;
            taxPaid.details['gestPeriod'] = 0;
            openingBalanceValue.details['gestPeriod']=0;

          for (let i = 1; i <= 12; i++) {
           
            additonalMachineValue.details[i] = parseFloat(data.data[0].startupCost.whichMonth) == i ? parseFloat(data.data[0].startupCost.totalCost) : 0;
            totalNetGSTPaidValue.details[i] =
            parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].gst_collected_on_sales) -
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i]) :
              0) -
            (i === 1 ?
              (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST)) ?
                parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST) :
                0) :
              0);       
           
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            let subsidy = parseFloat(data.data[0].sourcesOfFund.whichMonth) == i ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
            totalInflowValue.details[i] = parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSales[i]) + parseFloat(productValue.salesProjection.totalMonthlyCashInflowOfSalesGST[i]) + subsidy;
            console.log(totalInflowValue)
            subTotalVariable.details[i] =
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.monthlyInputPurchaseB4GST?.[i]) :
              0) +
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.monthlyInputGSTPaid?.[i]) :
              0) +
            parseFloat(totalNetGSTPaidValue.details[i]) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].commission) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].transport) 
          
            + parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.details?.[i]);      
            
            repaymentValue.details[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.details.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.details.principle[i])
            totalSales.details[i] = parseFloat(productValue.salesProjection.totalMonthlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]);
            salesCommission.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.commission?.details[i]) || 0), 0);
            transportTotal.details[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.transport?.details[i]) || 0), 0);
            totalnetGSTOnSale.details[i] = parseFloat(productValue.salesProjection.totalMonthlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST);
            totalVariable.details[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.details[i].variableCostGST) + parseFloat(totalnetGSTOnSale.details[i]);
            totalGrossProfit.details[i] = parseFloat(totalSales.details[i]) -(parseFloat(totalVariable?.details[i])+parseFloat(commission?.details[i])+parseFloat(fgTransport?.details[i]))
            -parseFloat(productValue?.salesProjection?.creditCardFee?.details?.[i]);      


            totalTaxPaid.details[i] =((parseFloat(totalGrossProfit.details[i])
            + other.details[i] 
            - totalFixedCost.details[i]) * taxRateYear1 / 100) < 0 ? 0 :( parseFloat(totalGrossProfit.details[i])
             +other.details[i]
             - totalFixedCost.details[i]) * taxRateYear1 / 100;
             

             actualTaxPaid.details[i] =((parseFloat(totalGrossProfit.details[i])
            + other.details[i] 
            - totalFixedCost.details[i]) * taxRateYear1 / 100) 
              console.log(actualTaxPaid)
          } 

          totalTaxPaid.details[3] = totalTaxPaid.details[1] + totalTaxPaid.details[2] + totalTaxPaid.details[3];
          totalTaxPaid.details[1] = 0;
          totalTaxPaid.details[2] = 0;

          totalTaxPaid.details[6] = totalTaxPaid.details[4] + totalTaxPaid.details[5] + totalTaxPaid.details[6];
          totalTaxPaid.details[4] = 0;
          totalTaxPaid.details[5] = 0;
          totalTaxPaid.details[9] = totalTaxPaid.details[7] + totalTaxPaid.details[8] + totalTaxPaid.details[9];
          totalTaxPaid.details[7] = 0;
          totalTaxPaid.details[8] = 0;
          totalTaxPaid.details[12] = totalTaxPaid.details[10] + totalTaxPaid.details[11] + totalTaxPaid.details[12];
          totalTaxPaid.details[10] = 0;
          totalTaxPaid.details[11] = 0;
          let subsidyYear1 = parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 12 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
          let subsidyYear2 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 12 && parseFloat(data.data[0].sourcesOfFund.whichMonth) <= 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
          let subsidyYear3 = parseFloat(data.data[0].sourcesOfFund.whichMonth) > 24 ? parseFloat(data.data[0].sourcesOfFund.whichMachine) : 0;
          closingBalanceValue.details['gestPeriod'] = totalInflowValue.details['gestPeriod'] - totalOutflowValue.details['gestPeriod'];
          console.log(closingBalanceValue, totalInflowValue, totalOutflowValue)
          for (let i = 1; i <= 12; i++) {
            totalOutflowValue.details[i] =parseFloat(additonalMachineValue.details[i]) + parseFloat(subTotalVariable.details[i])+parseFloat(totalCash.details[i])+parseFloat(totalTaxPaid.details[i])+parseFloat(repaymentValue.details[i]);
            let previousMonth = (i - 1) == 0 ? 'gestPeriod' : i - 1;
            openingBalanceValue.details[i] = closingBalanceValue.details[previousMonth];
            closingBalanceValue.details[i] = openingBalanceValue.details[i] + (totalInflowValue.details[i] - totalOutflowValue.details[i]);

          }
          additonalMachineValue.aggregate[1] = parseFloat(data.data[0].startupCost.whichMonth) <= 12 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[2] = parseFloat(data.data[0].startupCost.whichMonth) > 12 && parseFloat(data.data[0].startupCost.whichMonth) <= 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          additonalMachineValue.aggregate[3] = parseFloat(data.data[0].startupCost.whichMonth) > 24 ? parseFloat(data.data[0].startupCost.totalCost) : 0;
          for (let i = 1; i <= 3; i++) {
            salesCommission.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.commission?.aggregate[i]) || 0), 0);
            transportTotal.aggregate[i] = productValue.salesProjection.fieldProductsName.reduce((sum, product) => parseFloat(sum) +( parseFloat(product.transport?.aggregate[i]) || 0), 0);

            totalNetGSTPaidValue.aggregate[i] =
            parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) -
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i]) :
              0) -
            (i === 1 ?
              (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST)) ?
                parseFloat(productValue?.rawMaterialProcurement?.initialInputCostGST) :
                0) :
              0);           
               totalSales.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlySalesRevenue[i]) + parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]);
            let subsidy = i == 1 ? subsidyYear1 : i == 2 ? subsidyYear2 : subsidyYear3;
            totalInflowValue.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSales[i]) + parseFloat(productValue.salesProjection.totalYearlyCashInFlowOfSalesGST[i]) + subsidy;
            totalnetGSTOnSale.aggregate[i] = parseFloat(productValue.salesProjection.totalYearlyGSTCollectOnSale[i]) - parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST);
            totalVariable.aggregate[i] = parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostB4GST) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].variableCostGST) + parseFloat(totalnetGSTOnSale.aggregate[i]);
            totalGrossProfit.aggregate[i] = parseFloat(totalSales.aggregate[i]) -( parseFloat(totalVariable?.aggregate[i])+parseFloat(commission?.aggregate[i])+parseFloat(fgTransport?.aggregate[i]))
            -parseFloat(productValue?.salesProjection?.creditCardFee?.aggregate?.[i]);      
            let taxRate = i == 1 ? taxRateYear1 : i == 2 ? taxRateYear2 : taxRateYear3;
            // subTotalVariable.aggregate[i] =  parseFloat(productValue.rawMaterialProcurement.yearlyInputPurchaseB4GST[i]) + parseFloat(productValue.rawMaterialProcurement.yearlyInputGSTPaid[i]) + parseFloat(totalNetGSTPaidValue.aggregate[i])  + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].commission) + parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].transport)+ parseFloat(cred.aggregate[i]);

            subTotalVariable.aggregate[i] =
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.yearlyInputPurchaseB4GST?.[i]) :
              0) +
            (!isNaN(parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i])) ?
              parseFloat(productValue?.rawMaterialProcurement?.yearlyInputGSTPaid?.[i]) :
              0) +
            parseFloat(totalNetGSTPaidValue.aggregate[i]) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].commission) +
            parseFloat(productValue.salesProjection.totalMonthlyValues.aggregate[i].transport) 
            + parseFloat(productValue?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[i]);      
                repaymentValue.aggregate[i] = parseFloat(data.data[0].sourcesOfFund.bankFinancial.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.machinarySuppliers.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.mfi.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.rawMaterialSup.aggregate.principle[i]) + parseFloat(data.data[0].sourcesOfFund.familyFriends.aggregate.principle[i])
                console.log(parseFloat(additonalMachineValue.details[i]) , parseFloat(subTotalVariable.details[i]),parseFloat(totalCash.details[i]),parseFloat(totalTaxPaid.details[i]),parseFloat(repaymentValue.details[i]))

                console.log(totalTaxPaid, actualTaxPaid);
                totalTaxPaid.aggregate[i] = i == 1 
                ? Object.values(actualTaxPaid?.details).reduce((sum, item) => sum + item, 0)<0?0:parseFloat(Object.values(actualTaxPaid?.details).reduce((sum, item) => sum + item, 0)) <0?0:parseFloat(Object.values(totalTaxPaid?.details).reduce((sum, item) => sum + item, 0)) 


                : (() => {
                    const grossProfit = parseFloat(totalGrossProfit.aggregate[i]);
                    const otherValue = parseFloat(other.aggregate[i]);
                    const fixedCost = parseFloat(totalFixedCost.aggregate[i]);
                    const taxAmount = (grossProfit + otherValue - fixedCost) * taxRate / 100;
                    return taxAmount < 0 ? 0 : taxAmount;
                })();
          } 
          for (let i = 1; i <= 3; i++) {
            totalOutflowValue.aggregate[i] = subTotalVariable.aggregate[i]+totalCash.aggregate[i]+totalTaxPaid.aggregate[i]+repaymentValue.aggregate[i]+parseFloat(additonalMachineValue.aggregate[i]);
            openingBalanceValue.aggregate[i] = (i == 1 ? openingBalanceValue.details[1] : parseFloat(closingBalanceValue.aggregate[i - 1]));
            closingBalanceValue.aggregate[i] = parseFloat(openingBalanceValue.aggregate[i]) + (parseFloat(totalInflowValue.aggregate[i]) - parseFloat(totalOutflowValue.aggregate[i]));
          }
          setTotalInflow(totalInflowValue);
          setTotalNetGSTPaid(totalNetGSTPaidValue);
          setTotalSalesRevenue(totalSales);
          setNetGSTOnSale(totalnetGSTOnSale);
          setTotalVariableCost(totalVariable);
          setGrossProfit(totalGrossProfit); 
          setTaxPaid(totalTaxPaid);
          setAdditonalMachine(additonalMachineValue);
          setSubTotalVariableCost(subTotalVariable);
          setRepayment(repaymentValue);
          setTotalOutflow(totalOutflowValue);
          setOpeningBalance(openingBalanceValue);
          setClosingBalance(closingBalanceValue);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);



  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }



  return (
    <>
      <h5>Proforma Cash Flow Statement</h5>
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead className="table-light">
            <tr>
              <th className=""></th>
              <th className="text-center md-width">Description</th>
              <th className="text-center md-width">Startup</th>
              <th className="text-center md-width">Month 1</th>
              <th className="text-center md-width">Month 2</th>
              <th className="text-center md-width">Month 3</th>
              <th className="text-center md-width">Month 4</th>
              <th className="text-center md-width">Month 5</th>
              <th className="text-center md-width">Month 6</th>
              <th className="text-center md-width">Month 7</th>
              <th className="text-center md-width">Month 8</th>
              <th className="text-center md-width">Month 9</th>
              <th className="text-center md-width">Month 10</th>
              <th className="text-center md-width">Month 11</th>
              <th className="text-center md-width">Month 12</th>
              <th className="text-center md-width">Year 1</th>
              <th className="text-center md-width">Year 2</th>
              <th className="text-center md-width">Year 3</th>
            </tr>
          </thead>
          <tbody className="table-border-bottom-0">
            <tr>
              <td>A</td>
              <td>Opening Balance</td> 
              <td className="text-center">-</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[1])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[2])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[3])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[4])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[5])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[6])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[7])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[8])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[9])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[10])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[11])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.details[12])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(openingBalance.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Inflows</strong></td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Sources of Fund</strong></td>
            </tr>
            {sourcesOfFundData && sourcesOfFundData.fieldLineItemsName && sourcesOfFundData.fieldLineItemsName.map((x, index) => {
              return (
                <tr key={index}>
                  <td></td>
                  <td className="text-right lg-width">
                    {x["Description"]}
                  </td>
                  <td className="text-right">
                    {getNumericFormat(x.Amount)}
                  </td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                  <td className="text-center">-</td>
                </tr>
              );
            })}
            <tr>
              <td></td>
              <td className="text-right lg-width">Subsidy</td>
              <td className="text-center"> - </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 1 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 2 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 3 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 4 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 5 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 6 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 7 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 8 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 9 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 10 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 11 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) == 12 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) <= 12 ? parseFloat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) > 12 && parseFloat(sourcesOfFundData.whichMonth) <= 24 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
              <td className="text-right">{parseFloat(sourcesOfFundData.whichMonth) > 24 ? getNumericFormat(sourcesOfFundData.whichMachine) : ''} </td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Sales Reciept</strong></td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Sales Cash inflow B4 GST</td>
              <td className="text-center"> - </td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSales && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSales[12])}</td>
              {/* <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSales?.[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSales?.[2])}</td>
              <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSales?.[3])}</td> */}
            <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSales?.[1])}</td>
              <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSales?.[2])}</td>
              <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSales?.[3])}</td>
           
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">GST Collected</td>
              <td className="text-center"> - </td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[3])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[4])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[5])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[6])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[7])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[8])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[9])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[10])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[11])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyCashInflowOfSalesGST && getNumericFormat(productData.salesProjection.totalMonthlyCashInflowOfSalesGST[12])}</td>
              {/* <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSalesGST?.[1])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSalesGST?.[2])}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalYearlySalesRevenue && getNumericFormat(productData?.salesProjection?.totalYearlyCashInflowOfSalesGST?.[3])}</td> */}
            <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSalesGST?.[1])}</td>
              <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSalesGST?.[2])}</td>
              <td className="text-right">{getNumericFormat(productData?.salesProjection?.totalYearlyCashInFlowOfSalesGST?.[3])}</td>
           
            </tr>
            <tr>
              <td>B</td>
              <td><strong>Total Inflow</strong></td>
              <td className="text-right">{getNumericFormat(totalInflow.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Outflows</strong></td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Tangible Assets</td>
              <td className="text-right">{getNumericFormat(startupCostData.totalDepreciableAssets + startupCostData.totalNonDepreciableAssets)}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Additional Machine/Equipments</td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[1])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[2])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[3])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[4])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[5])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[6])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[7])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[8])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[9])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[10])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[11])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[12])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Intangible Assets</td>
              <td className="text-right">{startupCostData.totalIntangiblesAssets ? getNumericFormat(startupCostData.totalIntangiblesAssets) : 0}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Deposits & Advances</td>
              <td className="text-right">{startupCostData.totalDepositsAndAdvancesAssets ? getNumericFormat(startupCostData.totalDepositsAndAdvancesAssets) : 0}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Sub Total Asset Aquisition</td>
              <td className="text-right">{getNumericFormat(startupCostData?.totalDepreciableAssets + startupCostData?.totalNonDepreciableAssets+startupCostData?.totalIntangiblesAssets +startupCostData?.totalDepositsAndAdvancesAssets)}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[1])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[2])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[3])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[4])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[5])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[6])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[7])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[8])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[9])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[10])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[11])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.details[12])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(additonalMachine.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td colSpan="18"><strong>Variable Cost (Material & Net GST)</strong></td>
            </tr>
            {/* <tr>
              <td></td>
              <td className="text-right lg-width">Initial Input Cost</td>
              <td className="text-right">{startupCostData.initialRawMaterialPurchase ? getNumericFormat(startupCostData.initialRawMaterialPurchase) : 0}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
            </tr> */}
            <tr>
              <td></td>
              <td className="text-right lg-width">Monthly Input Purchase B4 GST</td>
              <td className="text-right">{getNumericFormat(productData?.rawMaterialProcurement?.initialInputCost)}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[1])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[2])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[3])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[4])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[5])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[6])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[7])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[8])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[9])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[10])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[11])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.monthlyInputPurchaseB4GST[12])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.yearlyInputPurchaseB4GST[1])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.yearlyInputPurchaseB4GST[2])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputPurchaseB4GST && getNumericFormat(productData.rawMaterialProcurement.yearlyInputPurchaseB4GST[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">GST paid on Material (Input Credit) </td>
              <td className="text-right">{getNumericFormat(productData?.rawMaterialProcurement?.initialInputCostGST)}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[1])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[2])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[3])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[4])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[5])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[6])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[7])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[8])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[9])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[10])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[11])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.monthlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.monthlyInputGSTPaid[12])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.yearlyInputGSTPaid[1])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.yearlyInputGSTPaid[2])}</td>
              <td className="text-right">{productData && productData.rawMaterialProcurement && productData.rawMaterialProcurement.yearlyInputGSTPaid && getNumericFormat(productData.rawMaterialProcurement.yearlyInputGSTPaid[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Net GST on Sale</td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalNetGSTPaid.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Comission Paid on Sales</td>
              <td className="text-center"> - </td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].commission)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].commission)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Finished goods Transport</td>
              <td className="text-center"> - </td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[1].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[2].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[3].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[4].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[5].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[6].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[7].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[8].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[9].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[10].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[11].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.details[12].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[1].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[2].transport)}</td>
              <td className="text-right">{productData && productData.salesProjection && productData.salesProjection.totalMonthlyValues && getNumericFormat(productData.salesProjection.totalMonthlyValues.aggregate[3].transport)}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Credit Card fee</td>
              <td className="text-center"> - </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[1]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[1]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[2]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[2]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[3]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[3]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[4]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[4]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[5]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[5]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[6]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[6]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[7]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[7]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[8]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[8]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[9]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[9]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[10]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[10]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[11]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[11]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[12]? productData?.salesProjection?.creditCardFeeCashInFlow?.details?.[12]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[1]? productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[1]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[2]? productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[2]:0)} </td>
              <td className="text-right">  {getNumericFormat(productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[3]? productData?.salesProjection?.creditCardFeeCashInFlow?.aggregate?.[3]:0)} </td>

           
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width"><strong>Sub Total Material VC + Net GST</strong></td>
              <td className="text-right">{getNumericFormat(startupCostData?.initialRawMaterialPurchase)}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[1])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[2])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[3])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[4])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[5])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[6])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[7])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[8])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[9])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[10])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[11])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.details[12])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(subTotalVariableCost.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td className="text-right lg-width">Fixed Expenses (Cash)</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[3])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[4])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[5])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[6])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[7])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[8])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[9])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[10])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[11])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.details[12])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(fixedCostCash.aggregate[3])}</td>
            </tr>
            {/* <tr>
              <td></td>
              <td className="text-right lg-width">Initial Cash</td>
              <td className="text-right">{startupCostData.initialCash ? getNumericFormat(startupCostData.initialCash) : 0}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
            </tr> */}
           
          
          
            <tr>
              <td></td>
              <td className="text-right lg-width">Repayment</td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(repayment.details[1])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[2])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[3])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[4])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[5])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[6])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[7])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[8])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[9])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[10])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[11])}</td>
              <td className="text-right">{getNumericFormat(repayment.details[12])}</td>
              <td className="text-right">{getNumericFormat(repayment.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(repayment.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(repayment.aggregate[3])}</td>
            </tr>
            <tr>
              <td></td>
              <td><strong>Tax Paid</strong></td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(taxPaid.details[3])}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(taxPaid.details[6])}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(taxPaid.details[9])}</td>
              <td className="text-center"> - </td>
              <td className="text-center"> - </td>
              <td className="text-right">{getNumericFormat(taxPaid.details[12])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(taxPaid.aggregate[3])}</td>
            </tr>
            <tr>
              <td>C</td>
              <td><strong>Total Outflow</strong></td>
              <td className="text-right">{getNumericFormat(totalOutflow.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalOutflow.aggregate[3])}</td>
            </tr>
            <tr>
              <td>D</td>
              <td><strong>(Surplus/Deficit) for the month (B-C)</strong></td>
              <td className="text-right">{getNumericFormat(totalInflow.details['gestPeriod'] - totalOutflow.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[1] - totalOutflow.details[1])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[2] - totalOutflow.details[2])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[3] - totalOutflow.details[3])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[4] - totalOutflow.details[4])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[5] - totalOutflow.details[5])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[6] - totalOutflow.details[6])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[7] - totalOutflow.details[7])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[8] - totalOutflow.details[8])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[9] - totalOutflow.details[9])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[10] - totalOutflow.details[10])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[11] - totalOutflow.details[11])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.details[12] - totalOutflow.details[12])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[1] - totalOutflow.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[2] - totalOutflow.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(totalInflow.aggregate[3] - totalOutflow.aggregate[3])}</td>
            </tr>
            <tr>
              <td>E</td>
              <td><strong>Closing Balance = (A+B)</strong></td>
              <td className="text-right">{getNumericFormat(closingBalance.details['gestPeriod'])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[1])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[2])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[3])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[4])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[5])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[6])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[7])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[8])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[9])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[10])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[11])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.details[12])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.aggregate[1])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.aggregate[2])}</td>
              <td className="text-right">{getNumericFormat(closingBalance.aggregate[3])}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
}
export default CashFlow;
