import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import Layout from "common/components/layout";
import axios from "axios";
import { ApiUrl } from "API/config";
import Loader from "common/components/loader";
function RepaymentBackUp(props) {
  const operation = useParams().op;
  const params = useParams();
  const [sourcesOfFundData, setSourcesOfFundData] = useState({});
  const [isLoader, setIsLoader] = useState(false);
  const getNumericFormat = (num) => {
    const numberFormatter = Intl.NumberFormat("en-IN", {
      maximumFractionDigits: 0,
    });
    return num ? numberFormatter.format(num) : 0;
  };
  useEffect(() => {
    if (operation == "Edit") {
      setIsLoader(true);
      axios
        .get(`${ApiUrl}protege/${params.id}`)
        .then((data) => {
          setSourcesOfFundData(data.data[0].sourcesOfFund ? data.data[0].sourcesOfFund : {});
          setIsLoader(false);
        })
        .catch((err) => {
          setIsLoader(false);
        });
    }
  }, []);
  if (isLoader) {
    return (
      <Layout>
        <Loader></Loader>
      </Layout>
    );
  }
  if (sourcesOfFundData.fieldLineItemsName) {
    return (
      <>
        <h5>Repayment Schedule</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead className="table-light">
              <tr>
                <th className="text-center md-width">Description</th>
                <th className="text-center md-width">Month 1</th>
                <th className="text-center md-width">Month 2</th>
                <th className="text-center md-width">Month 3</th>
                <th className="text-center md-width">Month 4</th>
                <th className="text-center md-width">Month 5</th>
                <th className="text-center md-width">Month 6</th>
                <th className="text-center md-width">Month 7</th>
                <th className="text-center md-width">Month 8</th>
                <th className="text-center md-width">Month 9</th>
                <th className="text-center md-width">Month 10</th>
                <th className="text-center md-width">Month 11</th>
                <th className="text-center md-width">Month 12</th>
                <th className="text-center md-width">Year 1</th>
                <th className="text-center md-width">Year 2</th>
                <th className="text-center md-width">Year 3</th>
              </tr>
            </thead>
            <tbody className="table-border-bottom-0">
              <tr>
                <td className="text-right lg-width">
                  Family/friends/relatives
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[3])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[4])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[5])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[6])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[7])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[8])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[9])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[10])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[11])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.details.principle[12])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.aggregate.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.aggregate.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.familyFriends.aggregate.principle[3])}
                </td>
              </tr>
              <tr>
                <td className="text-right lg-width">
                  Bank/Financial Institution
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[3])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[4])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[5])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[6])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[7])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[8])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[9])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[10])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[11])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.details.principle[12])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.aggregate.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.aggregate.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.bankFinancial.aggregate.principle[3])}
                </td>
              </tr>
              <tr>
                <td className="text-right lg-width">
                  Machinery Supplier Credit
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[3])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[4])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[5])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[6])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[7])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[8])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[9])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[10])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[11])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.details.principle[12])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.aggregate.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.aggregate.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.machinarySuppliers.aggregate.principle[3])}
                </td>
              </tr>
              <tr>
                <td className="text-right lg-width">
                  Raw Material Supplier Credit
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[3])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[4])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[5])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[6])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[7])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[8])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[9])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[10])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[11])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.details.principle[12])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.aggregate.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.aggregate.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.rawMaterialSup.aggregate.principle[3])}
                </td>
              </tr>
              <tr>
                <td className="text-right lg-width">
                  MFI
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[3])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[4])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[5])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[6])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[7])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[8])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[9])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[10])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[11])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.details.principle[12])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.aggregate.principle[1])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.aggregate.principle[2])}
                </td>
                <td className="text-right">
                  {getNumericFormat(sourcesOfFundData && sourcesOfFundData.mfi.aggregate.principle[3])}
                </td>
              </tr>
              <tr>
                <td className="text-right lg-width">
                  Total
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[1]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[1]) + parseFloat(sourcesOfFundData.mfi.details.principle[1]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[1]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[1]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[2]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[2]) + parseFloat(sourcesOfFundData.mfi.details.principle[2]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[2]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[2]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[3]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[3]) + parseFloat(sourcesOfFundData.mfi.details.principle[3]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[3]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[3]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[4]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[4]) + parseFloat(sourcesOfFundData.mfi.details.principle[4]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[4]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[4]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[5]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[5]) + parseFloat(sourcesOfFundData.mfi.details.principle[5]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[5]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[5]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[6]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[6]) + parseFloat(sourcesOfFundData.mfi.details.principle[6]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[6]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[6]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[7]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[7]) + parseFloat(sourcesOfFundData.mfi.details.principle[7]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[7]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[7]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[8]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[8]) + parseFloat(sourcesOfFundData.mfi.details.principle[8]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[8]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[8]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[9]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[9]) + parseFloat(sourcesOfFundData.mfi.details.principle[9]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[9]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[9]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[10]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[10]) + parseFloat(sourcesOfFundData.mfi.details.principle[10]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[10]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[10]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[11]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[11]) + parseFloat(sourcesOfFundData.mfi.details.principle[11]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[11]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[11]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.details.principle[12]) + parseFloat(sourcesOfFundData.machinarySuppliers.details.principle[12]) + parseFloat(sourcesOfFundData.mfi.details.principle[12]) + parseFloat(sourcesOfFundData.rawMaterialSup.details.principle[12]) + parseFloat(sourcesOfFundData.familyFriends.details.principle[12]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.aggregate.principle[1]) + parseFloat(sourcesOfFundData.machinarySuppliers.aggregate.principle[1]) + parseFloat(sourcesOfFundData.mfi.aggregate.principle[1]) + parseFloat(sourcesOfFundData.rawMaterialSup.aggregate.principle[1]) + parseFloat(sourcesOfFundData.familyFriends.aggregate.principle[1]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.aggregate.principle[2]) + parseFloat(sourcesOfFundData.machinarySuppliers.aggregate.principle[2]) + parseFloat(sourcesOfFundData.mfi.aggregate.principle[2]) + parseFloat(sourcesOfFundData.rawMaterialSup.aggregate.principle[2]) + parseFloat(sourcesOfFundData.familyFriends.aggregate.principle[2]))}
                </td>
                <td className="text-right">
                  {sourcesOfFundData && getNumericFormat(parseFloat(sourcesOfFundData.bankFinancial.aggregate.principle[3]) + parseFloat(sourcesOfFundData.machinarySuppliers.aggregate.principle[3]) + parseFloat(sourcesOfFundData.mfi.aggregate.principle[3]) + parseFloat(sourcesOfFundData.rawMaterialSup.aggregate.principle[3]) + parseFloat(sourcesOfFundData.familyFriends.aggregate.principle[3]))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </>
    );
  }
}
export default RepaymentBackUp;
